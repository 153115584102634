import React from 'react';
import WOW from "wowjs";
import Banner1 from '../components/main-banner/Banner1';
import Download_bgImage_Banner from '../components/banner/Download_bgImage_Banner';
import InfoBannerLeft from '../components/InfoBanner_left';
import Features_Info from '../components/Features_Info';
import ReviewSlider from '../components/Reviews-Slider';
import ChatWithFriends from '../components/ChatWithFriends';
import NewsLetter from '../components/NewsLetter';
import Faqs from '../components/Faqs';
import Blog from '../components/Blog';
import MoreApps from '../components/MoreApps';
import Accessible_All_Platform_Banner from '../components/Accessible_All_Platform_Banner';
import Info_Img_Content from '../components/Info_Img_Content';
import Info_Content_Img from '../components/Info_Content_Img';

class Home extends React.Component {
	componentDidMount() {
		const wow = new WOW.WOW(
			{ live: false }
		);
		wow.init();
	}
	render() {
		return (
			<>
				{/* PAGE CONTENT */}
				<div id="page" className="page">	

					{/* BANNER-ONE */}
					<Banner1
						// All Props information is preset in "components/main-banner/Banner1.jsx"
						section_class={`bg_purple_img bg_shape_03`}
						font_color={`white-color`}
						banner_app_logo={`home/app-logo.png`}
						banner_title={`Spend Your Time with Loved Ones`}
						banner_description={`An enim nullam tempor sapien and gravida donec ipsum undo pretium porta integer justo velna`} // banner description
						app_store_img={`store/appstore.png`}
						appstore_link={``} // appstore link
						play_store_img={`store/googleplay.png`}
						playstore_link={``}
						banner_content_img={`home/hero-1-img.png`}
					/>

					{/* FEATURES-INFO*/}
					<Features_Info
						// All Props information is preset in "components/Features_Info.jsx"
						layout_style={3}
						section_class={`features-2 pt-50`}
						sec_title={`Quick, Easy and Secure`}
						sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
						info_data={
							[
								{
									title: `Quick Access`,
									description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
									icon: `flaticon-call`
								},
								{
									title: `Group Messaging`,
									description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
									icon: `flaticon-user-1`
								},
								{
									title: `Alerts & Notifications`,
									description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
									icon: `flaticon-message`
								},
								{
									title: `Strong Encryption`,
									description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
									icon: `flaticon-chat-bubble`
								}
							]
						}
						icon_color={''}
					/>

					{/* INFO-LEFT-IMG-RIGHT-CONTENT */}
					<Info_Img_Content
						// All Props information is preset in "Info_Img_Content.Jsx"
						section_class={`wide-70`}
						lft_image={`home/img-11.png`}
						img_Class={``}
						sub_title={`Unlimited Access`}
						title={`Stay connected with friends easily`}
						p_content_data={
							[
								`Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus and egestas sapien egestas vitae nemo volute`,
								`Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus and egestas sapien egestas vitae nemo volute`,
							]
						}
						button={``}
						button_content={``}
						button_bg_and_hover={``}
						button_icon={``}
						bgShape_design_1={``}
						bgShape_design_2={``}
					/>

					{/* INFO-LEFT-CONTENT-RIGHT-IMG */}
					<Info_Content_Img
						// All Props information is preset in "Info_Content_Img.Jsx"
						section_class={`mb-70`}
						sub_title={``}
						title={``}
						icon_content_data={
							[
								{
									icon: `flaticon-video-player`,
									title: `Text, Voice & Video Calls`,
									description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
								},
								{
									icon: `flaticon-smiley`,
									title: `Stickers, Emojis, Themes`,
									description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
								},
								{
									icon: `flaticon-image`,
									title: `Share Files & Media`,
									description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
								}
							]
						}
						icon_color={'stateblue-color'}
						button={``}
						button_content={``}
						button_bg_and_hover={``}
						button_icon={``}
						rtl_image={`home/img-05.png`}
						img_Class={``}
						bgShape_design_1={'bg_el_01'}
						bgShape_design_2={'bg_el_05'}
					/>

					{/* INFO BANNER-LEFT */}
					<InfoBannerLeft
						// All Props information is preset in "components/InfoBanner_left.jsx"						
						section_bg_class={`bg_purple_img`}
						font_color={`white-color`}
						sec_img={`home/img-04.png`}
						sub_title={`Fastest Messaging`}
						title={`Unlimited calling, texting and picture messaging`}
						content={`Aliqum mullam blandit and tempor sapien donec ipsum gravida porta. Velna vitae auctor congue magna impedit ligula risus. Mauris donec ligula and magnis undo sapien sagittis sapien pretium enim gravida purus ligula`}
					/>

					{/* INFO-LEFT-CONTENT-RIGHT-IMG */}
					<Info_Content_Img
						// All Props information is preset in "Info_ContentIcon_Img.Jsx"
						section_class={`wide-100 mt-inverse-40`}
						sub_title={`Perfect Integration`}
						title={`Work smarter with powerful features`}
						ul_content_data={
							[
								`Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
								`Quaerat sodales sapien euismod purus blandit`,
								`Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`,
							]
						}
						rtl_image={`home/img-02.png`}						
						img_Class={`pc-15`}
						button={``}
						button_content={``}
						button_bg_and_hover={``}
						button_icon={``}
						bgShape_design_1={``}
						bgShape_design_2={``}
					/>

					{/* FEATURES-INFO */}
					<Features_Info
						// All Props information is preset in "components/Features_info.jsx"
						layout_style={2}
						section_class={`pb-50`}
						sec_title={`Get Ready to Be Surprised`}
						sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
						info_data={[
							{
								icon: `flaticon-ads`,
								title: `Friendly Interface`,
								description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
							},
							{
								icon: `flaticon-switch`,
								title: `Powerful Options`,
								description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
							},
							{
								icon: `flaticon-pantone`,
								title: `Customization`,
								description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
							},
							{
								icon: `flaticon-user-1`,
								title: `Multiple Accounts`,
								description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
							},
							{
								icon: `flaticon-browser`,
								title: `Online Verification`,
								description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
							},
							{
								icon: `flaticon-spam`,
								title: `Spam Protection`,
								description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
							}
						]}
						icon_color={'stateblue-color'}
					/>

					{/* REVIEWS-SLIDER*/}
					<ReviewSlider
						// All Props information is preset in "components/Reviews-Slider.jsx"
						section_class={`bg_aliceblue`}
						sub_title={`Why People Use DesCode®`}
						sec_title={`Our Happy Customers`}
						sec_description={`Aliquam augue suscipit luctus neque purus ipsum neque dolor primis undo tempus, blandit and cursus varius`}						
					/>

					{/* CHAT WITH FRIENDS */}
					<ChatWithFriends
						// All Props information is preset in "components/ChatWithFriends.jsx"		
						section_class={`wide-100`}
						sec_title={`Chat with Your Friends Easily`}
						sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
						sec_img={`home/socials.png`}
						button={true}
						button_title={`See Descode in Action `}
						button_bg_and_hover={`btn-rose tra-rose-hover`}
						button_icon={`flaticon-play-button`}
						button_description={`Available on Android, iOS and macOS`}
					/>


					{/* INFO-LEFT-IMG-RIGHT-CONTENT */}
					<Info_Img_Content
						// All Props information is preset in "Info_Img_Content.Jsx"
						section_class={`pb-100`}
						lft_image={`home/img-01.png`}
						img_Class={``}
						sub_title={`Register in 30 Seconds`}
						title={`Lightning fast and super powerful`}
						p_content_data={
							[
								`Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus at egestas sapien vitae nemo egestas volute and turpis dolores aliquam quaerat sodales a sapien`,
							]
						}
						button={``}
						button_content={``}
						button_bg_and_hover={``}
						button_icon={``}
						bgShape_design_1={``}
						bgShape_design_2={``}
					/>

					{/* DOWNLOAD BG_IMAGE BANNER*/}
					<Download_bgImage_Banner
						// All Props information is preset in "components/Download_bg_img.jsx"
						section_class={'bg_selfy'}
						font_color={`white-color`}
						sub_title={'Voice & Video Calls'}
						title={'Keep Your Favorite People Close to You'}
						description={`Aliquam a augue suscipit, luctus neque at purus ipsum neque dolor primis libero tempus, blandit 
								posuere orci auctor purus euismod an aliquam quaerat purus`}
						img1={'store/appstore-white.png'}
						img2={'store/googleplay-white.png'}
					/>

					{/* ACCESSIBLE ALL PLATFORM */}
					<Accessible_All_Platform_Banner
						// All Props information is preset in "components/Accessible_All_Platform_Banner.jsx"
						section_class={`whitesmoke_shape`}
						sec_title={`Accessible for All Platforms`}
						sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
						sec_img={`home/browsers.png`}
						button={''} // true/false
						button_content={``}
						button_bg_and_hover={``} // btn-tra-grey skyblue-hover
						button_icon={``} // flaticon-play-button
						button_description={``}
					/>

					{/* FAQS */}
					<Faqs
						// All Props information is preset in "components/Faqs.jsx"
						section_class={``}
						sub_title={`FAQs`}
						title={`Common Questions`}
						content={`Aliquam augue suscipit luctus neque purus ipsum neque dolor primis undo tempus, blandit and cursus varius`}
					/>

					{/* MORE APPS */}
					<MoreApps
						// All Props information is preset in "components/MoreApps.jsx"
						section_class={`bg_aliceblue wide-70 `}
						sec_title={`More Apps by CODEX Theme`}
						sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor `}
					/>

					{/* BLOG */}
					<Blog
						// All Props information is preset in "components/Blog.jsx"
						section_class={``}
						sec_title={`Our Tips and Latest News`}
						sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor `}
						itemStart={3}
						itemEnd={6}
					/>

					{/* NEWSLETTER */}
					<NewsLetter
						// All Props information is preset in "components/NewsLetter.jsx"	
						section_class={``}
						sub_title={`Subscribe to Our Newsletter`}
						title={`Stay up to date with our news, ideas and updates`}
						input_placeholder={``}
						btn_content={`Subscribe Now`}
						button_bg_and_hover={`btn-rose tra-rose-hover`} //Like btn-tra-grey skyblue-hover					
					/>
				</div>
				{/* END PAGE CONTENT */}
			</>
		)
	}
}
export default Home;