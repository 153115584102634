import React from 'react';
import WOW from "wowjs";
import Features_Info from '../components/Features_Info';
import Banner6 from '../components/main-banner/Banner6';
import Info_Img_Content from '../components/Info_Img_Content';
import Info_Content_Img from '../components/Info_Content_Img';
import ChatWithFriends from '../components/ChatWithFriends';
import Reviews from '../components/Reviews';
import Accessible_All_Platform_Banner from '../components/Accessible_All_Platform_Banner';
import Brand from '../components/Brand';
import Download_bgImage_Banner from '../components/banner/Download_bgImage_Banner';
import Download_bgPattern_Banner from '../components/banner/Download_bgPattern_Banner';
import Faq_simple from '../components/Faq_simple';
import Blog from '../components/Blog';
import NewsLetter from '../components/NewsLetter';

class Home6 extends React.Component {
  componentDidMount() {
    const wow = new WOW.WOW(
      { live: false }
    );
    wow.init();
  }
  render() {
    return (
      <>
        {/* PAGE CONTENT */}
        <div id="page" className="page">

          {/* BANNER-SIX */}
          <Banner6
            // All Props information is preset in "components/main-banner/Banner6.jsx"
            section_class={``}
            font_color={`white-color`}
            banner_title={`Connect With Friends`}
            banner_description={`Feugiat primis ligula risus auctor laoreet and pretium augue egestas mauris in cubilia rutrum justo`}

            // Button-1
            button_1={true}
            button_1_bg_and_hover={`btn-avocado tra-white-hover`}
            button_1_content={`Start For Free`}

            // Button-2
            button_2={true}
            button_2_content={`Watch In Action`}
            button_2_bg_and_hover={`btn-tra-white white-hover`}

            user_Count={`1.5M`}
            banner_main_img={`home/hero-6-img.png`}
          />

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`wide-70`}
            lft_image={`home/img-07.png`}
            img_Class={``}
            sub_title={`Unlimited Access`}
            title={`Spend your time with loved ones`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus and egestas sapien egestas vitae nemo volute`,
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus and egestas sapien egestas vitae nemo volute`,
              ]
            }
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`pb-100`}
            sub_title={`Perfect Integration`}
            title={`Work smarter with powerful features`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`,
              ]
            }
            rtl_image={`home/img-02.png`}
            img_Class={`pc-15`}
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />


          {/* FEATURES-INFO-1 */}
          <Features_Info
            // All Props information is preset in "components/Features_Info.jsx"
            layout_style={2}
            section_class={``}
            sec_title={`Get Ready to Be Surprised`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            icon_color={'skyblue-color'}
            info_data={[
              {
                icon: `flaticon-ads`,
                title: `Friendly Interface`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-switch`,
                title: `Powerful Options`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-pantone`,
                title: `Customization`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-user-1`,
                title: `Multiple Accounts`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-browser`,
                title: `Online Verification`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-spam`,
                title: `Spam Protection`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              }
            ]}
          />

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`wide-70`}
            lft_image={`home/img-04.png`}
            img_Class={``}
            sub_title={`Fastest Messaging`}
            title={`nlimited calling, texting and picture messaging`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus and egestas sapien egestas vitae nemo volute`
              ]
            }
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`mb-100`}
            sub_title={``}
            title={``}
            icon_content_data={
              [
                {
                  icon: `flaticon-video-player`,
                  title: `Text, Voice & Video Calls`,
                  description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
                },
                {
                  icon: `flaticon-smiley`,
                  title: `Stickers, Emojis, Themes`,
                  description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
                },
                {
                  icon: `flaticon-image`,
                  title: `Share Files & Media`,
                  description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
                }
              ]
            }
            icon_color={'skyblue-color'}
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            rtl_image={`home/img-05.png`}
            img_Class={``}
            bgShape_design_1={'bg_el_01'}
            bgShape_design_2={'bg_el_05'}
          />

          {/* CHAT WITH FRIENDS */}
          <ChatWithFriends
            // All Props information is preset in "components/ChatWithFriends.jsx"		
            section_class={`pb-70`}
            sec_title={`Chat with Your Friends Easily`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            sec_img={`home/socials.png`}
            button={true}
            button_title={`See Descode in Action `}
            button_bg_and_hover={`btn-skyblue tra-grey-hover`}
            button_icon={`flaticon-play-button`}
            button_description={`Available on Android, iOS and macOS`}
          />

          {/* REVIEWS SIMPLE */}
          <Reviews
            // All Props information is preset in "components/Reviews.jsx"
            section_class={`bg_aliceblue wide-60`}
            section_title={`Why People Use DesCode®`}
            section_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor `}
            app_store_img={`store/appstore.png`}
            appstore_link={``}
            play_store_img={`store/googleplay.png`}
            playstore_link={``}
            app_version_desc={`Available for iOS 8 and Android Devices From 5.5`}
          />

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`wide-50`}
            lft_image={`home/img-03.png`}
            img_Class={``}
            sub_title={`Beauty of Simplicity`}
            title={`Beautiful, award-winning design`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium
                ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo
                pretium a purus mauris`
              ]
            }
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* INFO-LEFT-UL_CONTENT-RIGHT-IMG-TWO */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`pb-60`}
            sub_title={`Totally Optimized`}
            title={`Intuitive features, powerful results`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`,
              ]
            }
            button={true}
            button_bg_and_hover={`btn-tra-white skyblue-hover`}
            button_content={`Find Out More`}
            rtl_image={`home/tablet.png`}
            img_Class={`content-13-img`}
            bgShape_design_1={''}
            bgShape_design_2={''}
          />

          {/* ACCESSIBLE ALL PLATFORM */}
          <Accessible_All_Platform_Banner
            // All Props information is preset in "components/Accessible_All_Platform_Banner.jsx"
            section_class={`whitesmoke_shape`}
            sec_title={`Accessible for All Platforms`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            sec_img={`home/browsers.png`}
            button={''} // true/false
            button_content={``}
            button_bg_and_hover={``} // btn-tra-grey skyblue-hover
            button_icon={``} // flaticon-play-button
            button_description={``}
          />

          {/* BRANDS-2 */}
          <Brand
            // All Props information is preset in "components/Brand.jsx"
            section_class={``}
            sec_title={`You might know Descode from:`}
            brand_images={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          />

          {/* DOWNLOAD BG_IMAGE BANNER*/}
          <Download_bgImage_Banner
            // All Props information is preset in "components/Download_bg_img.jsx"
            section_class={'bg_selfy'}
            font_color={`white-color`}
            sub_title={'Voice & Video Calls'}
            title={'Keep Your Favorite People Close to You'}
            description={`Aliquam a augue suscipit, luctus neque at purus ipsum neque dolor primis libero tempus, blandit 
								posuere orci auctor purus euismod an aliquam quaerat purus`}
            img1={'store/appstore-white.png'}
            img2={'store/googleplay-white.png'}
          />

          {/* FAQS-SIMPLE*/}
          <Faq_simple
            // All Props information is preset in "components/Faq_simple.jsx"	
            section_class={`wide-100`}
            sec_title={`Got Questions? Look Here`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
          />


          {/* DOWNLOAD BG PATTERN */}
          <Download_bgPattern_Banner
            // All Props information is preset in "components/Download_bgPattern_Banner.jsx"	
            section_class={`bg_skyblue bg_pattern_02 pt-100`}
            sec_img={`home/img-15.png`}
            title={`Showcase your app with Descode®`}
            content={`Augue egestas volutpat egestas augue purus cubilia laoreet and magna suscipit luctus dolor tempus`}
            img1={'store/appstore.png'}
            img2={'store/googleplay.png'}
            user={`7.296`}
          />

          {/* BLOG */}
          <Blog
            // All Props information is preset in "components/Blog.jsx"	
            section_class={`wide-60`}
            sec_title={`Our Tips and Latest News`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor `}
            itemStart={3}
            itemEnd={6}
          />

          {/* NEWSLETTER */}
          <NewsLetter
            // All Props information is preset in "components/NewsLetter.jsx"	
            section_class={``}
            sub_title={`Subscribe to Our Newsletter`}
            title={`Stay up to date with our news, ideas and updates`}
            input_placeholder={``}
            btn_content={`Subscribe Now`}
            button_bg_and_hover={`btn-skyblue tra-skyblue-hover`} //Like btn-tra-grey skyblue-hover					
          />
          
        </div>
        {/* END PAGE CONTENT */}
      </>
    )
  }
}
export default Home6; 