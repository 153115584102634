import React from 'react';

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  --Background Like this class "bg_pattern_01 | bg_pattern_02 | bg_pattern_03" | bg_purple_img | bg_sblue_img | bg_selfy | blue_gradient. 
//                         Other options find in style.css(Background Colors && Background Gradients && Background Images for Sections) 
//                      --ADD extra class like (pt-1/100, pb-1/100, pl-1/100, pr-1/100, "Your Own Class").

// (2) font_color = Content font color like (white-color | dark-color | grey-color | lightgrey-color | blue-color | green-color | purple-color | rose-color | sapphire-color | skyblue-color | stateblue-color | violet-color | yellow-color)
// (3) banner_subtitle = App Link
// (4) banner_title = Banner title
// (5) banner_description = Banner discription
// (6) button_content = Button description
// (7) button_bg_and_hover = Button background & on hover background like this ("avocado-hover button_bg_and_hover") [Other bg classes find in style.css "Button Color"]
// (8) info_description = Some description for form button
// (8) banner_image = banner right side image
const Banner3 = (props) => {
    return (
        <>
            <section id="hero-3" className={`bg_scroll hero-section division ${!props.section_class == '' ? props.section_class : ''}`}>
                <div className={`container ${!props.font_color == '' ? props.font_color : ''}`}>
                    <div className="row d-flex align-items-center">
                        <div className={`${props.banner_image == '' ? 'col-12 text-center' : 'col-md-7 col-lg-6'}`}>
                            <div className="hero-3-txt pc-10 mb-40 wow fadeInRight" data-wow-delay="0.4s">

                                {/* Banner sub title */}
                                {!props.banner_subtitle == '' ? <h5 className="h5-xs grey-color">{props.banner_subtitle}</h5> : ''}

                                {/* Banner title */}
                                {!props.banner_title == '' ? <h2 className="h2-xl">{props.banner_title}</h2> : ''}

                                {/* Banner description */}
                                {!props.banner_description == '' ? <p className="p-xl grey-color">{props.banner_description}</p> : ''}

                                <form name="requestform" className="request-form">
                                    <div className="input-group">

                                        {
                                            !props.button_content == ''
                                                ?
                                                <>
                                                    <input type="email" name="email" className="form-control email" placeholder="Your email address" required />
                                                    <span className="input-group-btn form-btn">
                                                        <button type="submit" className={`btn btn-md submit ${!props.button_bg_and_hover == '' ? props.button_bg_and_hover : 'btn-skyblue black-hover'}`}>{props.button_content}</button>
                                                    </span>
                                                </>
                                                : ''
                                        }
                                    </div>
                                </form>
                                {!props.info_description == '' ? <span className="os-version grey-color">{props.info_description}</span> : ''}
                            </div>
                        </div>
                        {
                            !props.banner_image == ''
                                ?
                                <div className="col-md-5 col-lg-6">
                                    <div className="hero-3-img text-center mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                                        <img className="img-fluid" src={require(`../../assets/images/${props.banner_image}`)} alt="hero" />
                                    </div>
                                </div>
                                : ''
                        }
                    </div>
                </div>
                <div className="bg_fixed wave_overlay" />
            </section>
        </>
    );
}

export default Banner3;
