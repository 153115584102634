import React from 'react';
import WOW from "wowjs";
import Banner7 from '../components/main-banner/Banner7';
import ReviewSlider from '../components/Reviews-Slider';
import Features_Info from '../components/Features_Info';
import Info_Content_Img from '../components/Info_Content_Img';
import Info_Img_Content from '../components/Info_Img_Content';
import Accessible_All_Platform_Banner from '../components/Accessible_All_Platform_Banner';
import ProgessbarSec from '../components/Progessbar_sec';
import Pricing from '../components/Pricing';
import Brand from '../components/Brand';
import Faqs from '../components/Faqs';
import Download_bgImage_Banner from '../components/banner/Download_bgImage_Banner';
import Info_Content_Img_bg from '../components/Info_Content_Img_bg';

class Home7 extends React.Component {
  componentDidMount() {
    const wow = new WOW.WOW(
      { live: false }
    );
    wow.init();
  }
  render() {
    return (
      <>
        {/* PAGE CONTENT */}
        <div id="page" className="page">

          {/* BANNER-SEVEN */}
          <Banner7
            // All Props information is preset in "components/main-banner/Banner2.jsx"
            section_class={`bg_fixed`}
            banner_image={`home/hero-7-img.png`}
            banner_image_btn_link={``}
            font_color={``}
            banner_title={`Smart & Secure Mobile Banking`}
            banner_description={`Feugiat primis ligula risus and auctor augue egestas mauri viverra tortor in iaculis magna feugiat mauris an ipsum placerat viverra tortor gravida purus`}
            app_store_img={`store/appstore.png`}
            appstore_link={``} // appstore link
            play_store_img={`store/googleplay.png`}
            playstore_link={``}
            version_desciption={`Current Release: Descode 2.1.09`}
          />

          {/* FEATURES-INFO */}
          <Features_Info
            // All Props information is preset in "components/Features_Info.jsx"
            layout_style={7}
            section_class={`pt-20 pb-100`}
            sec_title={`Manage all your payments with Descode mobile App`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius`}
            icon_color={`skyblue-color`}
            info_data={[
              {
                icon: `flaticon-mobile-phone`,
                title: `Quick Access`
              },
              {
                icon: `flaticon-chat-4`,
                title: `Smart Notifications`
              },
              {
                icon: `flaticon-spam`,
                title: `Spam Protection`
              },
              {
                icon: `flaticon-spam`,
                title: `Spam Protection`
              }
            ]}
          />

          <Info_Content_Img_bg
            // All Props information is preset in "Info_Content_Img_bg.Jsx"
            section_class={`mb-60`}
            content_bg={`lavender_gradient`}
            sub_title={``}
            title={`Work smarter with powerful automation`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus at blandit and purus ipsum primis and cubilia laoreet augue a luctus magna dolor luctus mauris pretium a sapien egestas luctus`,
                `Fringilla risus, luctus mauris auctor a purus euismod orci pretium purus pretium undo ligula rutrum tempor magna dolor`
              ]
            }
            main_image={`home/img-12.png`}
          />
          

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`wide-40`}
            lft_image={`home/img-21.png`}
            img_Class={``}
            sub_title={`Perfect Integration`}
            title={`Banking has never been so rewarding`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`
              ]
            }
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`pb-100`}
            sub_title={`Register in 30 Seconds`}
            title={`Lightning fast and super powerful`}
            p_content_data={
              [
                `Aliqum mullam blandit and tempor sapien donec lipsum gravida porta. Velna vitae auctor congue magna impedit ligula risus. Mauris donec ligula an impedit magnis`
              ]
            }
            rtl_image={`home/img-01.png`}
            img_Class={`pc-15`}
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* PROCESS-2 */}
          <ProgessbarSec
            // All Props information is preset in "components/Progessbar_sec.jsx"
            section_class={`bg_bank_01 wide-100 white-color`}
            sec_title={`Online invoices, payments, transfer funds — all in one solution`}
          />

          {/* ACCESSIBLE ALL PLATFORM */}
          <Accessible_All_Platform_Banner
            // All Props information is preset in "components/Accessible_All_Platform_Banner.jsx"
            section_class={`wide-100`}
            sec_title={`Accessible for All Platforms`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            sec_img={`home/browsers.png`}
            button={``}
            button_title={``}
            button_bg_and_hover={``}
            button_icon={``}
            button_description={``}
          />


          {/* FEATURES-INFO-1 */}
          <Features_Info
            // All Props information is preset in "components/Features_Info.jsx"
            layout_style={2}
            section_class={`pb-50`}
            sec_title={`Get Ready to Be Surprised`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            icon_color={'skyblue-color'}
            info_data={[
              {
                icon: `flaticon-online-banking`,
                title: `Mobile Banking`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-credit-card`,
                title: `Prepaid Visa Card`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-payment`,
                title: `Card Lock & Unlock`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-chat-4`,
                title: `Real-Time Notifications`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-contactless`,
                title: `Payments Activity`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-ads`,
                title: `Homescreen Widget`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              }
            ]}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`rel crocus_gradient bg_shape_02 wide-60 white-color`}
            sub_title={`Totally Optimized`}
            title={`Intuitive features, powerful results`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus at blandit purus ipsum primis and cubilia laoreet augue at luctus magna dolor luctus mauris pretium a sapien egestas luctus`,
                `Fringilla risus, luctus mauris auctor a purus euismod orci pretium purus pretium undo ligula rutrum tempor`
              ]
            }
            button={``}
            button_bg_and_hover={``}
            button_content={``}
            rtl_image={`home/tablet.png`}
            img_Class={`content-13-img`}
            bgShape_design_1={''}
            bgShape_design_2={''}
          />

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`wide-80`}
            lft_image={`home/img-03.png`}
            img_Class={``}
            sub_title={`Beauty of Simplicity`}
            title={`Beautiful, award-winning design`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`
              ]
            }
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* PRICING */}
          <Pricing
            // All Props information is preset in "components/Pricing.jsx"		
            section_class={`pb-60 `}
            sec_title={`Simple and Flexible Pricing`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
          />

          {/* REVIEWS-SLIDER*/}
          <ReviewSlider
            // All Props information is preset in "components/Reviews-Slider.jsx"
            section_class={`bg_aliceblue`}
            sub_title={`Reviews`}
            sec_title={`Our Happy Customers`}
            sec_description={`Aliquam augue suscipit luctus neque purus ipsum neque dolor primis undo tempus, blandit and cursus varius`}
          />

          {/* BRANDS-2 */}
          <Brand
            // All Props information is preset in "components/Brand.jsx"
            section_class={`wide-40`}
            sec_title={`Trusted by companies large and small:`}
            brand_images={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          />

          {/* SECTION DIVIDER */}
          <div className="divider-wrapper text-center"><div className="section-divider" /></div>

          {/* FAQS */}
          <Faqs
            // All Props information is preset in "components/Faqs.jsx"
            section_class={``}
            sub_title={`FAQs`}
            title={`Common Questions`}
            content={`Aliquam augue suscipit luctus neque purus ipsum neque dolor primis undo tempus, blandit and cursus varius`}
          />

          {/* DOWNLOAD BG IMAGE */}
          <Download_bgImage_Banner
            // All Props information is preset in "components/Download_bgImage_Banner.jsx"		
            section_class={`bg_mobile `}
            sub_title={`Powerful Settings`}
            title={`Your payments are secure, every time`}
            description={`Aliquam a augue suscipit, luctus neque at purus ipsum neque dolor primis libero tempus, blandit 
                              posuere orci auctor purus euismod an aliquam quaerat purus`}
            img1={'store/appstore-white.png'}
            img2={'store/googleplay-white.png'}
          />

        </div>
        {/* END PAGE CONTENT */}
      </>
    )
  }
}
export default Home7; 