import React from 'react';
import Question_List_Data from "../assets/json/Question_List_Data";

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  Theme classes like("pt-100 | mb-100 & Background class find in style.css") & your own class
// (2) sub_title =  Section sub title
// (3) title =  Section title
// (4) content =  Section content
// (5) Question_List_Data =  All qustion list data come from JSON file(assets/json/Question_List_Data.json).
//                  (1) item.question = Your question
//                  (2) item.answer = Your answer

const Faqs = (props) => {
    return (
        <>
            <section id="faqs-1" className={`faqs-section division ${!props.section_class == '' ? props.section_class : ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="faqs-1-title pc-10">
                                {/* sub title */}
                                {!props.sub_title == '' ? <span className="section-id grey-color">{props.sub_title}</span> : ''}
                                {/* title */}
                                {!props.title == '' ? <h2 className="h2-md mb-20">{props.title}</h2> : ''}
                                {/* content */}
                                {!props.content == '' ? <p className="p-lg">{props.content}</p> : ''}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div id="accordion" role="tablist" className="pc-20">
                                {/* START: Json file data loop*/}
                                {
                                    !Question_List_Data == ''
                                        ?
                                        Question_List_Data.map((item, index) =>
                                            <div className={`card grey-color ${index + 1 == Question_List_Data.length ? 'last-card' : ''}`} key={`question_${index + 1}`}>
                                                {/* question */}
                                                <div className="card-header" role="tab" id={`Question_heading_${index + 1}`}>
                                                    <h5 className="h5-sm">
                                                        <a data-toggle="collapse" href={`#Question_${index + 1}`} role="button" aria-expanded="true" aria-controls={`Question_${index + 1}`}>
                                                            {!item.question == '' ? item.question : ''}
                                                        </a>
                                                    </h5>
                                                </div>
                                                {/* answer */}
                                                <div id={`Question_${index + 1}`} className={`collapse ${index + 1 == 1 ? 'show' : ''}`} role="tabpanel" aria-labelledby={`Question_heading_${index + 1}`} data-parent="#accordion">
                                                    <div className="card-body">
                                                        <p className="p-lg">{!item.answer == '' ? item.answer : ''}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        : ''
                                }
                                {/* END: Json file data loop*/}
                            </div>
                            <div className="more-questions pc-20 mt-35">
                                <h5 className="h5-sm"><span className="flaticon-check"></span>
                                    Have more questions? <a href="/contacts" className="violet-color">Ask your question here</a>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Faqs;
