import React from 'react';

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  --Background Like this class "bg_pattern_01 | bg_pattern_02 | bg_pattern_03" | bg_purple_img | bg_sblue_img | bg_selfy | blue_gradient. 
//                         Other options find in style.css(Background Colors && Background Gradients && Background Images for Sections) 
//                      --ADD extra class like (pt-1/100, pb-1/100, pl-1/100, pr-1/100, "Your Own Class").
//                      --Content font color class like (white-color | dark-color | grey-color | lightgrey-color | blue-color | green-color | purple-color | rose-color | sapphire-color | skyblue-color | stateblue-color | violet-color | yellow-color)
// (2) lft_title = left side content title
// (3) lft_description = left side content description
// (4) lft_button_content = left side button content
// (4) lft_button_link = left side button Link
// (5) lft_button_icon = button icon (Ex:-"flaticon-play-button" -> Flateicon)

// (6) banner_content_img = Banner Main section image

// (7) rtl_title == right side content title
// (8) app_store_img = App store image
// (9) appstore_link = App store link
// (10) play_store_img = Play store image
// (11) playstore_link = play store link
// (12) user_review = right side content review description in totle USERS


const Banner5 = (props) => {
    return (
        <>
            <div id="hero-5" className={`rel hero-section division  ${!props.section_class == '' ? props.section_class : ''}`}>
                <div className="container">
                    <div className="row d-flex align-items-center">

                        {/* Banner Left Content */}
                        <div className="col-md-6 col-lg-4">
                            <div className="hero-5-txt wow fadeInRight" data-wow-delay="0.4s">

                                {/* Left Content Title */}
                                {!props.lft_title == '' ? <h3 className="h3-lg">{props.lft_title}</h3> : ''}

                                {/* Left Content Description */}
                                {!props.lft_description == '' ? <p className="p-lg grey-color">{props.lft_description}</p> : ''}

                                {/*  Left Content Link button */}
                                {
                                    !props.lft_button_content == ''
                                        ?
                                        <div className="hero-video-link">
                                            <a href={!props.lft_button_link == '' ? props.lft_button_link : '#/'} className="video-popup2 btn btn-md btn-video-link ico-30">
                                                {!props.lft_button_icon == '' ? <span className={props.lft_button_icon} /> : ''}
                                                {props.lft_button_content}
                                            </a>
                                        </div>
                                        : ''
                                }
                            </div>
                        </div>

                        {/* Banber Main Center Image */}
                        <div className="col-md-6 col-lg-4">
                            {
                                !props.banner_content_img == ''
                                    ?

                                    <div className="hero-5-img pc-15 text-center">
                                        <img className="img-fluid" src={require(`../../assets/images/${props.banner_content_img}`)} alt="hero" />
                                    </div>

                                    : ''
                            }
                        </div>
                        
                        {/* Banner Right Content*/}
                        <div className="col-md-6 col-lg-4">
                            <div className="hero-5-btns wow fadeInLeft" data-wow-delay="0.4s">

                                {/* Right Content Title */}
                                {!props.rtl_title == '' ? <h3 className="h3-lg">{props.rtl_title}</h3> : ''}

                                {/* STORE BADGES */}
                                <div className="stores-badge">
                                    {/* App store image */}
                                    {
                                        !props.app_store_img == ''
                                            ?
                                            <a href={`${!props.appstore_link == '' ? props.appstore_link : '#/'}`} className="store">
                                                <img className="appstore" src={require(`../../assets/images/${props.app_store_img}`)} alt="appstore-badge" />
                                            </a>
                                            : ''
                                    }

                                    {/* Play store image */}
                                    {
                                        !props.play_store_img == ''
                                            ?
                                            <a href={`${!props.playstore_link == '' ? props.playstore_link : '#/'}`} className="store">
                                                <img className="googleplay" src={require(`../../assets/images/${props.play_store_img}`)} alt="googleplay-badge" />
                                            </a>
                                            : ''
                                    }
                                </div>
                                {/* Rating */}
                                <div className="txt-block-rating">
                                    <div className="stars-rating grey-color">
                                        All Versions Rating
                                        <span className="flaticon-star ml-5" />
                                        <span className="flaticon-star" />
                                        <span className="flaticon-star" />
                                        <span className="flaticon-star" />
                                        <span className="flaticon-star-half-empty" />
                                        <p className="txt-rating">Based on {!props.user_review == '' ? props.user_review : '****'} user reviews</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Banner5;
