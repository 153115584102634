import React from 'react';
import Section_Title from './Section_Title';

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  Theme classes like("pt-100 | mb-100 & Background class find in style.css") & your own class
// (2) sec_title = Section title
// (3) sec_description = Section description

const MoreApps = (props) => {
    return (
        <>
            <section id="more-apps-1" className={`moreapps-section division ${!props.section_class == '' ? props.section_class : ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            
                            {/* Section Title */}
                            <Section_Title
                                title={props.sec_title}
                                description={props.sec_description}
                            />
                        </div>
                    </div>
                    <div className="abox-1-wrapper">
                        <div className="row">
                            <div className="col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                                <a href="#/">
                                    <div className="row abox-1 d-flex align-items-center">
                                        <div className="app-version"><span>2.0.1</span></div>
                                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo"> <img className="img-fluid" src={require('../assets/images/home/app-logo-1.png')} alt="app-logo" /> </div>
                                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">
                                            <h5 className="h5-xs">FastChat 2</h5>
                                            <p>Aliquam an augue luctus undo purus</p>
                                            <div className="app-rating ico-20"> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star-half-empty mr-5"></span> 4.3 </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                                <a href="#/">
                                    <div className="row abox-1 d-flex align-items-center">
                                        <div className="app-version"><span>1.3.9</span></div>
                                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo"> <img className="img-fluid" src={require('../assets/images/home/app-logo-2.png')} alt="app-logo" /> </div>
                                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">
                                            <h5 className="h5-xs">MuMemos</h5>
                                            <p>Aliquam an augue luctus undo purus</p>
                                            <div className="app-rating ico-20"> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star mr-5"></span> 5.0 </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6 wow fadeInUp" data-wow-delay="0.8s">
                                <a href="#/">
                                    <div className="row abox-1 d-flex align-items-center">
                                        <div className="app-version"><span>4.1.6</span></div>
                                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo"> <img className="img-fluid" src={require('../assets/images/home/app-logo-4.png')} alt="app-logo" /> </div>
                                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">
                                            <h5 className="h5-xs">Music Player</h5>
                                            <p>Aliquam an augue luctus undo purus</p>
                                            <div className="app-rating ico-20"> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star-half-empty mr-5"></span> 4.65 </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6 wow fadeInUp" data-wow-delay="1s">
                                <a href="#/">
                                    <div className="row abox-1 d-flex align-items-center">
                                        <div className="app-version"><span>14.1.0</span></div>
                                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo"> <img className="img-fluid" src={require('../assets/images/home/app-logo-3.png')} alt="app-logo" /> </div>
                                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">
                                            <h5 className="h5-xs">PowerSaver</h5>
                                            <p>Aliquam an augue luctus undo purus</p>
                                            <div className="app-rating ico-20"> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star-half-empty mr-5"></span> 4.21 </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6 wow fadeInUp" data-wow-delay="1.2s">
                                <a href="#/">
                                    <div className="row abox-1 d-flex align-items-center">
                                        <div className="app-version"><span>2.2.1</span></div>
                                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo"> <img className="img-fluid" src={require('../assets/images/home/app-logo-5.png')} alt="app-logo" /> </div>
                                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">
                                            <h5 className="h5-xs">StocksHub</h5>
                                            <p>Aliquam an augue luctus undo purus</p>
                                            <div className="app-rating ico-20"> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star-half-empty mr-5"></span> 4.45 </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6 wow fadeInUp" data-wow-delay="1.4s">
                                <a href="#/">
                                    <div className="row abox-1 d-flex align-items-center">
                                        <div className="app-version"><span>2.3.5</span></div>
                                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo"> <img className="img-fluid" src={require('../assets/images/home/app-logo-6.png')} alt="app-logo" /> </div>
                                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">
                                            <h5 className="h5-xs">Equalizer 2</h5>
                                            <p>Aliquam an augue luctus undo purus</p>
                                            <div className="app-rating ico-20"> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star mr-5"></span> 4.0 </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default MoreApps;
