import React from 'react';
import WOW from "wowjs";
import ReviewSlider from '../components/Reviews-Slider';
import Features_Info from '../components/Features_Info';
import Banner3 from '../components/main-banner/Banner3';
import Info_Img_Content from '../components/Info_Img_Content';
import Info_Content_Img from '../components/Info_Content_Img';
import InfoBannerLeft from '../components/InfoBanner_left';
import ProgessbarSec from '../components/Progessbar_sec';
import Download_bgImage_Banner from '../components/banner/Download_bgImage_Banner';
import ChatWithFriends from '../components/ChatWithFriends';
import Brand from '../components/Brand';
import Faq_simple from '../components/Faq_simple';
import MoreApps from '../components/MoreApps';
import Blog from '../components/Blog';
import NewsLetter from '../components/NewsLetter';

class Home3 extends React.Component {
  componentDidMount() {
    const wow = new WOW.WOW(
      { live: false }
    );
    wow.init();
  }
  render() {
    return (
      <>
        {/* PAGE CONTENT */}
        <div id="page" className="page">

          {/* BANNER-THREE */}
          <Banner3
            // All Props information is preset in "components/main-banner/Banner3.jsx"
            section_class={``}
            font_color={``}
            banner_subtitle={`Start. Connect. Enjoy`}
            banner_title={`A Mobile Hub For All Your Contacts`}
            banner_description={`Feugiat primis ligula risus auctor egestas augue viverra mauri at tortor iaculis magna feugiat mauris ipsum and viverra tortor and placerat gravida purus pretium`}
            button_content={`Get Started`}
            button_bg_and_hover={`btn-skyblue black-hover`}
            info_description={`Try Descode free for 14 days. Cancel anytime.`}
            banner_image={`home/hero-3-img.png`}
          />

          {/* FEATURES-INFO */}
          <Features_Info
            // All Props information is preset in "components/Features_Info.jsx"
            layout_style={4}
            section_class={`pt-100`}
            sec_title={`Get Ready to Be Surprised`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            icon_color={``}
            info_data={[
              {
                icon: `flaticon-streaming`,
                title: `Quick Access`,
                description: `Porta semper lacus and cursus risus undo it a ultrice sodales tempus egestas sapien`
              },
              {
                icon: `flaticon-user-1`,
                title: `Add To Group`,
                description: `Porta semper lacus and cursus risus undo it a ultrice sodales tempus egestas sapien`
              },
              {
                icon: `flaticon-echo-dot`,
                title: `Phonetic Search`,
                description: `Porta semper lacus and cursus risus undo it a ultrice sodales tempus egestas sapien`
              },
              {
                icon: `flaticon-notes`,
                title: `Notes & Tags`,
                description: `Porta semper lacus and cursus risus undo it a ultrice sodales tempus egestas sapien`
              }
            ]}
          />

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`wide-100`}
            lft_image={`home/img-02.png`}
            img_Class={``}
            sub_title={`Perfect Integration`}
            title={`Makes your address book lite and user friendly`}
            p_content_data={
							[
								`Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue
                luctus magna dolor luctus at egestas sapien vitae nemo egestas volute and turpis dolores aliquam quaerat
                sodales a sapien`,
							]
						}
						button={``}
						button_content={``}
						button_bg_and_hover={``}
						button_icon={``}
						bgShape_design_1={``}
						bgShape_design_2={``}
          />

          {/* INFO-LEFT-ICON_CONTENT-RIGHT-IMG-ONE */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`background_bg wide-100`}
            sub_title={`Handling with Ease`}
            title={`Sync all contacts with social profiles`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus and egestas sapien egestas vitae nemo volute`
              ]
            }
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`
              ]
            }
            icon_color={'stateblue-color'}
            rtl_image={`home/img-07.png`}
            bgShape_design_1={''}
            bgShape_design_2={''}
          />

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`wide-100`}
            lft_image={`home/img-01.png`}
            img_Class={``}
            sub_title={`Register in 30 Seconds`}
            title={`Lightning fast and super powerful`}
            p_content_data={
							[
								`Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue
                luctus magna dolor luctus at egestas sapien vitae nemo egestas volute and turpis dolores aliquam quaerat
                sodales a sapien`,
							]
						}
						button={``}
						button_content={``}
						button_bg_and_hover={``}
						button_icon={``}
						bgShape_design_1={``}
						bgShape_design_2={``}
          />

          {/* FEATURES-INFO-1 */}
          <Features_Info
            // All Props information is preset in "components/Features_Info.jsx"
            layout_style={2}
            section_class={`pb-60`}
            sec_title={`Get Ready to Be Surprised`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            icon_color={'skyblue-color'}
            info_data={[
              {
                icon: `flaticon-roundabout`,
                title: `Sync Across Accounts`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-files`,
                title: `Business Cards Scanner`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-notifications`,
                title: `Birthday Reminders`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-delete`,
                title: `Merge Duplicate Contacts`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-cloud-computing`,
                title: `Contacts Storage & Backup`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-user`,
                title: `Call Blocking & Identification`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              }
            ]}
          />

          {/* INFO BANNER-LEFT */}
          <InfoBannerLeft
            // All Props information is preset in "components/InfoBanner_left.jsx"						
            section_bg_class={`bg-inner bg_aliceblue pt-100`}
            font_color={``}
            sec_img={`home/img-04.png`}
            sub_title={`Fastest Messaging`}
            title={`Send text, voice, picture messages more faster`}
            content={`Aliqum mullam blandit and tempor sapien donec ipsum gravida porta. Velna vitae auctor congue magna impedit ligula risus. Mauris donec ligula and magnis undo sapien sagittis sapien pretium enim gravida purus ligula`}
          />

          {/* INFO-LEFT-ICON_CONTENT-RIGHT-IMG-ONE */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`wide-60`}
            sub_title={`Totally Optimized`}
            title={`Intuitive features, powerful results`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`
              ]
            }
            rtl_image={`home/tablet.png`}
            img_Class={`content-13-img`}
            button={true}
            button_content={`See Descode in Action`}
            button_icon={`flaticon-play-button`}
          />

          {/* PROCESS-2 */}
          <ProgessbarSec
            // All Props information is preset in "components/Progessbar_sec.jsx"
            section_class={`pb-100`}
            sec_title={`Customize and make your contact manager as unique as you are`}
          />

          {/* DOWNLOAD BG IMAGE */}
          <Download_bgImage_Banner
            // All Props information is preset in "components/Download_bg_img.jsx"
            section_class={'bg_selfy'}
            font_color={`white-color`}
            sub_title={'Voice & Video Calls'}
            title={'Keep Your Favorite People Close to You'}
            description={`Aliquam a augue suscipit, luctus neque at purus ipsum neque dolor primis libero tempus, blandit 
								posuere orci auctor purus euismod an aliquam quaerat purus`}
            img1={'store/appstore-white.png'}
            img2={'store/googleplay-white.png'}
          />

          {/* CHAT WITH FRIENDS */}
          <ChatWithFriends
            // All Props information is preset in "components/ChatWithFriends.jsx"		
            section_class={`pt-100`}
            sec_title={`Chat with Your Friends Easily`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            sec_img={`home/socials.png`}
            button={``}
            button_title={``}
            button_bg_and_hover={``}
            button_icon={``}
            button_description={``}
          />

          {/* BRANDS-2 */}
          <Brand
            // All Props information is preset in "components/Brand.jsx"
            sec_title={`You might know Descode from:`}
            brand_images={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          />

          {/* REVIEWS-SLIDER*/}
          <ReviewSlider
            // All Props information is preset in "components/Reviews-Slider.jsx"
            section_class={`bg_aliceblue`}
            sub_title={`Our Happy Customers`}
            sec_title={`Why Customers Love Us`}
            sec_description={``}
          />

          {/* INFO-LEFT-ICON_CONTENT-RIGHT-IMG-ONE */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`wide-100`}
            sub_title={`Beauty of Simplicity`}
            title={`Beautiful, award-winning design`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`
              ]
            }
            rtl_image={`home/img-03.png`}
          />

          {/* FAQS-SIMPLE*/}
          <Faq_simple
            // All Props information is preset in "components/Faq_simple.jsx"	
            section_class={`pb-100`}
            sec_title={`Got Questions? Look Here`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
          />

          {/* MORE APPS */}
          <MoreApps
            // All Props information is preset in "components/MoreApps.jsx"
            section_class={`bg_whitesmoke  wide-70 `}
            sec_title={`More Apps by CODEX Theme`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor `}
          />

          {/* BLOG */}
          <Blog
            // All Props information is preset in "components/Blog.jsx"
            section_class={``}
            sec_title={`Our Tips and Latest News`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor `}
            itemStart={2}
            itemEnd={5}
          />

          {/* NEWSLETTER */}
          <NewsLetter
            // All Props information is preset in "components/NewsLetter.jsx"	
            section_class={``}
            sub_title={`Subscribe to Our Newsletter`}
            title={`Stay up to date with our news, ideas and updates`}
            input_placeholder={``}
            btn_content={`Subscribe Now`}
            button_bg_and_hover={`btn-skyblue tra-skyblue-hover`}            
          />
        </div>
        {/* END PAGE CONTENT */}
      </>
    )
  }
}
export default Home3 