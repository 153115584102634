import React from 'react';
import WOW from "wowjs";
import Blog_Data from "../assets/json/Blog_Data";
import Pagination from "../components/Pagination";

class BlogListing extends React.Component {
  constructor() {
    super();
    this.state = {
        blogItem: Blog_Data,
        pageOfItems: [], 
    };
    
    this.onChangePage = this.onChangePage.bind(this);
  }

  onChangePage(pageOfItems) {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  }

  componentDidMount() {
    const wow = new WOW.WOW(
      {live: false}
    );
    wow.init();
  }

  render() {    
    return(
      <>
        {/* PAGE CONTENT */}
        <div id="page" className="page">
          
          {/* BLOG */}
          <section id="blog-page" className="bg_whitesmoke hero-offset-nav pb-60 blog-page-section division">
            <div className="container">
              <div className="row">	
                <div className="col-md-10 offset-md-1">
                  <div className="section-title text-center pc-50 mb-70">		
                    <h3 className="h3-lg">Relevant news, tech stuff, and more for you. Welcome to our blog</h3>	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius
                    </p>
                  </div>	
                </div>
              </div>
              {/* FEATURED POST */}
              <div className="rel blog-post featured-post wide-post">
                <div className="row d-flex align-items-center">
                  <div className="featured-badge text-center ico-30 bg_whitesmoke yellow-color">
                    <span className="flaticon-star" />
                  </div>
                  {/* BLOG POST IMAGE */}
                  <div className="col-lg-7 blog-post-img">
                    <img className="img-fluid" src={require('../assets/images/blog/post-1-img.jpg')} alt="blog-post" />	
                  </div>
                  {/* BLOG POST TEXT */}
                  <div className="col-lg-5 blog-post-txt">
                    {/* Post Tag */}
                    <p className="p-md post-tag">Descode News</p>	
                    {/* Post Link */}
                    <h5 className="h5-xl">
                      <a href="/descode/SinglePost">Tempor sapien donec gravida a suscipit and porta justo vitae</a>
                    </h5>
                    {/* Text */}
                    <p className="p-md">Aliqum mullam blandit vitae and tempor sapien and donec lipsum gravida a porta 
                      undo velna dolor in cubilia laoreet
                    </p>
                    {/* Post Meta */}
                    <div className="post-meta">
                      <div className="post-author-avatar"><img src={require('../assets/images/blog/post-author-1.jpg')} alt="author-avatar" /></div>
                      <p>12 min read</p>
                    </div>	
                  </div>	
                </div>   
              </div>
              {/* POSTS WRAPPER */}
              <div className="posts-wrapper">
                <div className="row">
                  <div className="col-md-12">
                    <h5 className="h5-lg posts-category">Latest Articles</h5>
                  </div>
                </div>
                <div className="row">

                  {/*START: BLOG POST LOOP */}
                  {this.state.pageOfItems.map((item, index) =>                                       
                    <div className="col-md-6 col-lg-4" key={'blogKey_' + index}>
                      <div className="blog-post mb-40 wow fadeInUp" data-wow-delay='0.2s'>	
                        <a href="/descode/SinglePost">
                          {/* BLOG POST IMAGE */}
                          <div className="blog-post-img">
                            <img className="img-fluid" src={require('../assets/images/blog/' + item.image)}  alt="blog-post" />                            
                          </div>
                          {/* BLOG POST TEXT */}
                          <div className="blog-post-txt">
                            {/* Post Tag */}
                            <p className="p-md post-tag">{item.tag}</p>	
                            {/* Post Link */}
                            <h5 className="h5-xs">
                              {item.title}
                            </h5>
                            {/* Text */}
                            <p className="p-md">{item.content}</p>
                            {/* Post Meta */}
                            <div className="post-meta">
                              <div className="post-author-avatar">
                                <img src={require('../assets/images/blog/' + item.authorImg)} alt="author-avatar" />
                              </div>
                              <p>{item.read}</p>
                            </div>	                          
                          </div>
                        </a>
                      </div>
                    </div>      
                  )}
                  {/*END: BLOG POST LOOP*/}

                </div>                 
                <div className="row">
                  <div className="col-12">
                    <div className="bg_whitesmoke pt-50 page-pagination division">

                        {/* START PAGE PAGINATION */}
                        <Pagination items={this.state.blogItem} onChangePage={this.onChangePage} />
                        {/* END PAGE PAGINATION */}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* GEOMETRIC OVERLAY */}
            <div className="bg_fixed geometric_overlay" />
          </section>

          {/* NEWSLETTER-1 */}
          <section id="newsletter-1" className="bg_whitesmoke pb-20 newsletter-section division">
            <div className="container">
              <div className="newsletter-wrapper bg-white">
                <div className="row d-flex align-items-center">
                  <div className="col-lg-6">
                    <div className="newsletter-txt">	
                      <span className="section-id">Subscribe to Our Newsletter</span>
                      <h4 className="h4-xl">Stay up to date with our news, ideas and updates</h4>	
                    </div>								
                  </div>
                  <div className="col-lg-6">
                    <form className="newsletter-form">
                      <div className="input-group">
                        <input type="email" autoComplete="off" className="form-control" placeholder="Your email address" required id="s-email" />								
                        <span className="input-group-btn">
                          <button type="submit" className="btn btn-md btn-skyblue tra-skyblue-hover">Subscribe Now</button>
                        </span>										
                      </div>	
                      <label htmlFor="s-email" className="form-notification" />
                    </form>							
                  </div>	 
                </div>
              </div>  
            </div>	
          </section>
          
        </div>
        {/* END PAGE CONTENT */}
      </>
    )
  }
}
export default BlogListing; 