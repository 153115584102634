import React from 'react';

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  Background Like this class "bg_chat | bg_fit_01 | bg_fit_02 | bg_mobile | bg_bank_01 | bg_bank_02 | bg_purple_img | bg_sblue_img | bg_selfy | bg_pattern_01 | bg_pattern_02 | bg_pattern_03"
//                      ADD extra class like pt-1/100, pb-1/100, pl-1/100, pr-1/100 and "Your Own Class".
// (2) sec_title = Section title    
// (3) sec_description = Section description
// (4) sec_img = Section Image
// (5) button = Section button (true/false)
// (6) button_bg_and_hover = Button background & on hover background like this class ("avocado-hover button_bg_and_hover") [Other classes find in style.css "Button Color"]
// (7) button_title = Button title
// (8) button_icon = Flaticon class name (Ex:- "flaticon-play-button")
// (9) button_description = Button description

const ChatWithFriends = (props) => {
    return (
        <>
            <section className={`content-section division ${!props.section_class == false ? `bg_cls ${props.section_class}` : ''}`}>
                <div className="container">

                    {/* SECTION TITLE */}
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="section-title text-center mb-60">
                                {/* Title */}
                                {!props.sec_title == '' ? <h2 className="h2-md">{props.sec_title}</h2> : ''}
                                {/* Description */}
                                {!props.sec_description == '' ? <p className="p-xl">{props.sec_description}</p> : ''}
                            </div>
                        </div>
                    </div>

                    {/* IMAGE BLOCK */}
                    {
                        !props.sec_img == ''
                            ?
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                                        <img className="img-fluid" src={require(`../assets/images/${props.sec_img}`)} alt="content" />
                                    </div>
                                </div>
                            </div>
                            : ''
                    }

                    {/* DOWNLOAD BUTTON */}
                    {
                        !props.button == false
                            ?
                            <div className="row">
                                <div className={`col-md-12`}>
                                    <div className="content-11-btn ico-20 ico-right mt-60 wow fadeInUp" data-wow-delay="0.6s">
                                        {/* Button */}
                                        <a href="#/" className={`video-popup2 btn btn-md ${!props.button_bg_and_hover == '' ? props.button_bg_and_hover : ''}`}>
                                            {!props.button_title == '' ? props.button_title : 'Action'}

                                            {!props.button_icon == ''
                                                ?
                                                <span className={`${props.button_icon}`} />
                                                : ''
                                            }
                                        </a>
                                        {/* Description */}
                                        {
                                            !props.button_description == ''
                                                ?
                                                <span className="os-version">{props.button_description}</span>
                                                : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            : ''
                    }
                </div>
            </section>
        </>
    );
}

export default ChatWithFriends;
