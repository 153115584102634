import React,{useState} from 'react';

const BackToTop = () => {
    const [visible, setVisible] = useState(false)
  
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
            setVisible(true)
        } 
        else if (scrolled <= 300){
            setVisible(false)
        }
    };
    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'          
        });
    };
    window.addEventListener('scroll', toggleVisible);

    return (
        <>
            <div id="scrollUp" style={{display: visible ? 'inline' : 'none'}} onClick={scrollToTop}></div>
        </>
    );
}

export default BackToTop;
