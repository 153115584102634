import React from 'react';
import Section_Title from './Section_Title';

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  Background Like this class "bg_chat | bg_fit_01 | bg_fit_02 | bg_mobile | bg_bank_01 | bg_bank_02 | bg_purple_img | bg_sblue_img | bg_selfy | bg_pattern_01 | bg_pattern_02 | bg_pattern_03"
//                      ADD extra class like pt-1/100, pb-1/100, pl-1/100, pr-1/100 and "Your Own Class".

// (2) Section side description we provide 3 Type style:- [(1) icon_content_data, (2) ul_content_data, (3) p_content_data] As your requirement choice variable
//     Ex:- icon_content_data = {
//                                  [
//                                     {
//                                       icon:`flaticon-video-player` --> ICON CLASS NAME
//                                       title:`Text, Voice & Video Calls`, --> TITLE
//                                       description:`Ligula risus auctor tempus.` --> DESCRIPTION
//                                     },
//                                  ]
//                               }
//     Ex:- ul_content_data = {[ `Description-1`,`Description-2`,`Description-3`]}
//     Ex:- p_content_data  = {[ `Description-1`,`Description-2`,`Description-3`]}
// (3) button = Content section button (True/False)
// (4) button_bg_and_hover = Button background & on hover background like this ("btn-tra-grey skyblue-hover") [Other bg classes find in style.css "Button Color"]
// (5) button_content = Button title
// (6) button_icon = Flaticon class name (Ex:- "flaticon-play-button")
// (7) icon_color = white-color | dark-color | grey-color | lightgrey-color | blue-color | green-color | purple-color | rose-color | sapphire-color | skyblue-color | stateblue-color | violet-color | yellow-color
// (8) rtl_image =  Section side image path set in "IMAGE" folder inside like (`home/img-05.png`) 
// (9) img_Class = Section side image parent class
// (10) bgShape_design_1 = Background shape design class (bg_el_01 - bg_el_05)
// (11) bgShape_design_2 = ""

const Info_ContentIcon_Img = (props) => {
    return (
        <>
            <section className={`content-section division ${!props.section_class == '' ? props.section_class : ''}`}>
                <div className={`container`}>
                    <div id="cb-1-2" className="rel cbox-2">
                        <div className="row d-flex align-items-center m-row">
                            <div className='col-md-7 col-lg-6 m-bottom'>
                                <div className="txt-block left-column wow fadeInRight" data-wow-delay="0.6s">

                                    {/* Section title */}
                                    <Section_Title
                                        title_class={`text-left mb-0`}
                                        sub_title={props.sub_title}
                                        title={props.title}
                                    />

                                    {/*START: ICON Structure Content Loop ------(1)*/}
                                    {
                                        !props.icon_content_data == ''
                                            ?
                                            props.icon_content_data.map((item, index) =>
                                                <div className={`cbox ${index + 1 !== props.icon_content_data.length ? 'mb-30' : ''}`} key={index}>
                                                    {/* icon */}
                                                    <div className={`cbox-ico ico-65 ${!props.icon_color == '' ? props.icon_color : ''}`}>
                                                        <span className={`${!item.icon == '' ? item.icon : 'ICON_CLASS_NAME_NOT_FOUND'}`}></span>
                                                    </div>
                                                    <div className="cbox-txt">
                                                        {/* title */}
                                                        {!item.title == '' ? <h5 className="h5-sm">{item.title}</h5> : ''}
                                                        {/* description */}
                                                        {!item.description == '' ? <p className="p-lg">{item.description}</p> : ''}
                                                    </div>
                                                </div>
                                            )
                                            : ''
                                    }
                                    {/*END: ICON Structure Content Loop ------(1)*/}

                                    {/* && */}

                                    {/*START: P Structure Content Loop ------(2)*/}
                                    {
                                        !props.p_content_data == ''
                                            ?
                                            props.p_content_data.map((item, index) =>
                                                !item == '' ? <p className="p-lg" key={index}>{item}</p> : ''
                                            )
                                            : ''
                                    }
                                    {/*END: P Structure Content Loop ------(2)*/}

                                    {/* && */}

                                    {/*START: Ul Li Structure Content Loop ------(3)*/}
                                    {
                                        !props.ul_content_data == ''
                                            ?
                                            <ul className="simple-list">
                                                {
                                                    props.ul_content_data.map((item, index) =>
                                                        <li className="list-item" key={index}>
                                                            {!item == '' ? <p className="p-lg">{item}</p> : ''}
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                            : ''
                                    }
                                    {/*END: Ul Li Structure Content Loop ------(3)*/}

                                    {/* Button */}
                                    {!props.button == false
                                        ?
                                        <div className="ico-20 ico-right">
                                            <a href="/#" className={`btn ${!props.button_bg_and_hover == '' ? props.button_bg_and_hover : 'btn-tra-grey skyblue-hover'}`}>
                                                {!props.button_content == '' ? props.button_content : 'Action'}

                                                {/* button icon */}
                                                {!props.button_icon == ''
                                                    ?
                                                    <span className={`${props.button_icon}`} />
                                                    : ''
                                                }
                                            </a>
                                        </div>
                                        : ''
                                    }
                                </div>
                            </div>
                            {/* section right side image */}
                            {
                                !props.rtl_image == ''
                                    ?
                                    <div className="col-md-5 col-lg-6 m-top">
                                        <div className={`img-block right-column wow fadeInLeft ${!props.img_Class == '' ? props.img_Class : ''}`} data-wow-delay="0.6s">
                                            <img className="img-fluid" src={require(`../assets/images/${props.rtl_image}`)} alt="content" />
                                        </div>
                                    </div>
                                    : ''
                            }
                        </div>
                        {/* section background shape */}
                        <div className="tra-bkg-wrapper">
                            {!props.bgShape_design_1 == '' ? <div className={`${props.bgShape_design_1} wow fadeInRight`} data-wow-delay="1s"></div> : ''}
                            {!props.bgShape_design_2 == '' ? <div className={`${props.bgShape_design_2} fadeInRight`} data-wow-delay="1s"></div> : ''}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Info_ContentIcon_Img;
