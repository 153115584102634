import React from 'react';
import { Link } from "react-router-dom";
import BackToTop from './BackToTop';

class Footer extends React.Component {
    render(){
        return(
            <>
                <footer id="footer-3" className="footer division">
                    <div className="container">
                        <div className="row justify-content-lg-center">
                            <div className="col-lg-10">
                                <div className="row">
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                        <div className="footer-links mb-40">
                                            <ul className="foo-links clearfix">									
                                                <li><p className="p-md"><Link to="#">App Features</Link></p></li>													
                                                <li><p className="p-md"><Link to="#">Integrations</Link></p></li>
                                                <li><p className="p-md"><Link to="#">App Updates</Link></p></li>
                                                <li><p className="p-md"><Link to="#">Help & Support</Link></p></li>
                                                <li><p className="p-md"><Link to="#">Customer Stories</Link></p></li>						
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                        <div className="footer-links mb-40">
                                            <ul className="foo-links clearfix">													
                                                <li><p className="p-md"><Link to="#">Our Blog</Link></p></li>	
                                                <li><p className="p-md"><Link to="#">Customer Stories</Link></p></li>
                                                <li><p className="p-md"><Link to="#">Help & Support</Link></p></li>	
                                                <li><p className="p-md"><Link to="#">Life Chatting</Link></p></li>
                                                <li><p className="p-md"><Link to="#">Resources</Link></p></li>	
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                        <div className="footer-links mb-40">
                                            <ul className="foo-links clearfix">                                                
                                                <li><p className="p-md"><Link to="#">Press & Media</Link></p></li>	
                                                <li><p className="p-md"><Link to="#">Advertising</Link></p></li>
                                                <li><p className="p-md"><Link to="#">Privacy Policy</Link></p></li>		
                                                <li><p className="p-md"><Link to="#">Terms & Privacy</Link></p></li>					
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-8 col-lg-3">
                                        <div className="footer-store-badges text-right mb-40">
                                            <Link to="#" className="store">
                                                <img className="appstore" src={require('../assets/images/store/appstore.png')} alt="appstore-badge" />
                                            </Link>
                                            <Link to="#" className="store">
                                                <img className="googleplay" src={require('../assets/images/store/googleplay.png')} alt="googleplay-badge" />
                                            </Link> 
                                        </div>
                                    </div>	

                                </div>
                            </div>
                        </div>	 					
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="bottom-footer footer-copyright text-center">
                                    <p>All Rights Reserved by DesCode 2022 &copy;</p>
                                </div>
                            </div>
                        </div>
                    </div>	   										
                </footer>	
                
                {/* Back-To-TOP-Button */}
                <BackToTop/>          
            </>
        )
    }
}
export default Footer;    