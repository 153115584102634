import React from 'react';
import WOW from "wowjs";
import Info_Content_Img from '../components/Info_Content_Img';
import Info_Img_Content from '../components/Info_Img_Content';
import Features_Info from '../components/Features_Info';
import Accessible_All_Platform_Banner from '../components/Accessible_All_Platform_Banner';
import Download_bgImage_Banner from '../components/banner/Download_bgImage_Banner';

class Features extends React.Component {
  componentDidMount() {
    const wow = new WOW.WOW(
      { live: false }
    );
    wow.init();
  }
  render() {
    return (
      <>
        {/* PAGE CONTENT */}
        <div id="page" className="page">

          {/* INFO-CONTENT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`bg_whitesmoke  hero-offset-nav pb-60`}
            sub_title={`Register in 30 Seconds`}
            title={`Lightning fast and super powerful`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus at egestas sapien vitae nemo egestas volute and turpis dolores aliquam quaerat sodales a sapien`,
              ]
            }
            rtl_image={`home/img-01.png`}
            img_Class={`pc-15`}
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`pt-70 pb-70`}
            lft_image={`home/img-02.png`}
            img_Class={``}
            sub_title={`Perfect Integration`}
            title={`Work smarter with powerful features`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`,
              ]
            }
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`bg_whitesmoke pb-70 pt-70`}
            sub_title={`Beauty of Simplicity`}
            title={`Beautiful, award-winning design`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`,
              ]
            }
            button={``}
            button_bg_and_hover={``}
            button_content={``}
            rtl_image={`home/img-03.png`}
            img_Class={`pc-15`}
            bgShape_design_1={''}
            bgShape_design_2={''}
          />

          {/* FEATURES-INFO */}
          <Features_Info
            // All Props information is preset in "components/Features_info.jsx"
            layout_style={2}
            section_class={`pt-70 pb-50`}
            sec_title={`Get Ready to Be Surprised`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            info_data={[
              {
                icon: `flaticon-ads`,
                title: `Friendly Interface`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-switch`,
                title: `Powerful Options`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-pantone`,
                title: `Customization`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-user-1`,
                title: `Multiple Accounts`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-browser`,
                title: `Register in 30 Seconds`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-fingerprint`,
                title: `Secure Access`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              }
            ]}
            icon_color={'stateblue-color'}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`bg_purple_img wide-60 white-color`}
            sub_title={`Totally Optimized`}
            title={`Intuitive features, powerful results`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`,
              ]
            }
            button={true}
            button_bg_and_hover={`btn-tra-white skyblue-hover`}
            button_content={`Find Out More`}
            rtl_image={`home/tablet.png`}
            img_Class={`content-13-img`}
            bgShape_design_1={''}
            bgShape_design_2={''}
          />

          {/* ACCESSIBLE ALL PLATFORM */}
          <Accessible_All_Platform_Banner
            // All Props information is preset in "components/Accessible_All_Platform_Banner.jsx"
            section_class={`wide-100`}
            sec_title={`Accessible for All Platforms`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            sec_img={`home/browsers.png`}
            button={true} // true/false
            button_content={`See Descode in Action`}
            button_bg_and_hover={`btn-tra-grey skyblue-hover`} // btn-tra-grey skyblue-hover
            button_icon={`flaticon-play-button`} // flaticon-play-button
            button_description={`Available on Android, iOS and macOS`}
          />

          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`background_bg wide-100`}
            lft_image={`home/img-08.png`}
            img_Class={``}
            sub_title={`Unlimited Access`}
            title={`More productivity with less effort`}
            icon_content_data={
              [
                {
                  icon: `flaticon-video-player`,
                  title: `Text, Voice & Video Calls`,
                  description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
                },
                {
                  icon: `flaticon-smiley`,
                  title: `Stickers, Emojis, Themes`,
                  description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
                },
                {
                  icon: `flaticon-image`,
                  title: `Share Files & Media`,
                  description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
                }
              ]
            }
            icon_color={'stateblue-color'}
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* DOWNLOAD BG IMAGE */}
          <Download_bgImage_Banner
            // All Props information is preset in "components/Download_bgImage_Banner.jsx"		
            section_class={`bg_mobile wide-100`}
            sub_title={`Powerful Settings`}
            title={`Take Full Advantage of Smart Features`}
            description={`Aliquam a augue suscipit, luctus neque at purus ipsum neque dolor primis libero tempus, blandit posuere orci auctor purus euismod an aliquam quaerat purus`}
            img1={'store/appstore.png'}
            img2={'store/googleplay.png'}
          />

        </div>
        {/* END PAGE CONTENT */}
      </>
    )
  }
}
export default Features 