import React from 'react';

//*** COMPONET PROPS INFO: ***//
// (1) section_class = Like default Background/Pattern/Spacing class (bg_chat | bg_fit_01 | bg_fit_02 | bg_mobile | bg_bank_01 | bg_bank_02 | bg_purple_img | bg_sblue_img | bg_selfy | bg_pattern_01 | bg_pattern_02 | bg_pattern_03)
// (2) section_title = section title
// (3) section_description = section description
// (4) app_store_img = App store image
// (5) appstore_link = App store link 
// (6) play_store_img = Play store image
// (7) playstore_link = Play store link 
// (8) app_version_desc = App version description

const Reviews = (props) => {
    return (
        <>
            <section className={`reviews-section division ${!props.section_class == '' ? props.section_class : ''} `}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="section-title text-center mb-60">
                                {/* Title */}
                                {!props.section_title == '' ? <h2 className="h2-md">{props.section_title}</h2> : ''}
                                {/* description */}
                                {!props.section_description == '' ? <p className="p-xl">{props.section_description}</p> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="reviews-4-wrapper">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="reviews-4-column">
                                    <div className="review-4 wow fadeInUp" data-wow-delay="0.4s">
                                        <div className="review-4-txt">
                                            <p>Etiam sapien sem at sagittis congue an augue massa varius an egestas suscipit magna a tempus aliquet blandit <span className="txt-highlight">tempor sapien gravida donec</span> ipsum porta </p>
                                        </div>
                                        <div className="review-4-data">
                                            <div className="app-rating ico-20 yellow-color"> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star"></span> </div>
                                            <h5 className="h5-sm">John Sweet</h5>
                                            <p>Mac Store Review, USA</p>
                                        </div>
                                    </div>
                                    <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                                        <div className="review-4-txt">
                                            <p>An augue cubilia laoreet undo magna a suscipit egestas magna ipsum at ligula vitae <span className="txt-highlight">purus ipsum primis</span> cubilia </p>
                                        </div>
                                        <div className="review-4-data">
                                            <div className="app-rating ico-20 yellow-color"> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star-half-empty"></span> </div>
                                            <h5 className="h5-sm">Mark Hodges</h5>
                                            <p>Marketing Manager, Company</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="reviews-4-column">
                                    <div className="review-4 mt-25 wow fadeInUp" data-wow-delay="0.8s">
                                        <div className="review-4-txt">
                                            <p>Mauris donec ociis magnis undo sapien etiam sapien congue augue pretium and ligula augue lectus aenean ociis magna donec and magnis sapien sagittis ipsum <span className="txt-highlight">sapien congue tempor gravida </span> a donec ipsum porta justo integer a velna mollis laoreet vitae mauris undo tortor tempor sapien gravida donec ipsum porta </p>
                                        </div>
                                        <div className="review-4-data">
                                            <div className="app-rating ico-20 yellow-color"> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star"></span> <span className="flaticon-star"></span> </div>
                                            <h5 className="h5-sm">Dominic Johnson</h5>
                                            <p>Chief Strategist, Company</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="reviews-download-btn wow fadeInUp" data-wow-delay="1s">
                                <div className="stores-badge mb-5">
                                    {/* app store image */}
                                    {
                                        !props.app_store_img == ''
                                            ?
                                            <a href={`${!props.appstore_link == '' ? props.appstore_link : '#/'}`} className="store">
                                                <img className="appstore-original" src={require(`../assets/images/${props.app_store_img}`)} alt="appstore-badge" />
                                            </a>
                                            : ''
                                    }
                                    {/* play store image */}
                                    {
                                        !props.play_store_img == ''
                                            ?
                                            <a href={`${!props.playstore_link == '' ? props.playstore_link : '#/'}`} className="store">
                                                <img className="googleplay-original" src={require(`../assets/images/${props.play_store_img}`)} alt="googleplay-badge" />
                                            </a>
                                            : ''
                                    }
                                </div>
                                {/* Description */}
                                {!props.app_version_desc == '' ? <span className="os-version">{props.app_version_desc}</span> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Reviews;
