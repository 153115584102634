import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//*** COMPONET PROPS INFO: ***//
// (1) section_class =  Background Like this class "bg_chat | bg_fit_01 | bg_fit_02 | bg_mobile | bg_bank_01 | bg_bank_02 | bg_purple_img | bg_sblue_img | bg_selfy | bg_pattern_01 | bg_pattern_02 | bg_pattern_03"
//                      ADD extra class like pt-1/100, pb-1/100, pl-1/100, pr-1/100 and "Your Own Class".
// (2) sub_title = Section sub title
// (3) sec_title = Section title
// (4) sec_description = Section description

const ReviewSlider = (props) => {
    var reviews = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        speed: 2200,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    dots: true,
                    arrows: false,
                }
            },
        ]
    };
    return (
        <>
            <section id="reviews-2" className={`rel reviews-section division ${!props.section_class == '' ? props.section_class : ''}`}>
                <div className="container">
                    <div className="row">
                        {/* TESTIMONIALS TITLE */}
                        <div className="col-lg-4">
                            <div className="reviews-2-title pc-10">
                                {/* Sub title */}
                                {!props.sub_title == '' ? <span className="section-id grey-color">{props.sub_title}</span> : ''}
                                {/* Title */}
                                {!props.sec_title == '' ? <h2 className="h2-md mb-20">{props.sec_title}</h2> : ''}
                                {/* Description */}
                                {!props.sec_description == '' ? <p className="p-lg mb-0">{props.sec_description}</p> : ''}
                            </div>
                        </div>
                        {/* TESTIMONIALS CAROUSEL */}
                        <div className="col-lg-8">
                            <div className="reviews-2-wrapper">
                                {/* Review-Slider-Include */}
                                <Slider {...reviews}>
                                    {/* TESTIMONIAL #1 */}
                                    <div className="review-2 radius-08">
                                        {/* App Rating */}
                                        <div className="app-rating ico-20 yellow-color">
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                        </div>
                                        {/* Title */}
                                        <h5 className="h5-sm">Great Flexibility!</h5>
                                        {/* Testimonial Text */}
                                        <div className="review-2-txt">
                                            {/* Text */}
                                            <p className="p-lg grey-color">Etiam sapien sem at sagittis congue an augue massa varius egestas undo
                                                suscipit magna tempus undo aliquet
                                            </p>
                                            {/* Testimonial Author */}
                                            <h5 className="h5-xs">- Scott Boxer</h5>
                                        </div>
                                    </div>
                                    {/* TESTIMONIAL #2 */}
                                    <div className="review-2 radius-08">
                                        {/* App Rating */}
                                        <div className="app-rating ico-20 yellow-color">
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star-half-empty" />
                                        </div>
                                        {/* Title */}
                                        <h5 className="h5-sm">Simple and Useful!</h5>
                                        {/* Testimonial Text */}
                                        <div className="review-2-txt">
                                            {/* Text */}
                                            <p className="p-lg grey-color">At sagittis congue augue undo egestas magna ipsum vitae purus and ipsum
                                                primis suscipit
                                            </p>
                                            {/* Testimonial Author */}
                                            <h5 className="h5-xs">- Wendy T.</h5>
                                        </div>
                                    </div>
                                    {/* TESTIMONIAL #3 */}
                                    <div className="review-2 radius-08">
                                        {/* App Rating */}
                                        <div className="app-rating ico-20 yellow-color">
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                        </div>
                                        {/* Title */}
                                        <h5 className="h5-sm">I love this App!</h5>
                                        {/* Testimonial Text */}
                                        <div className="review-2-txt">
                                            {/* Text */}
                                            <p className="p-lg grey-color">Mauris donec ociis magnis and sapien etiam sapien congue undo augue pretium
                                                and ligula augue a lectus aenean magna
                                            </p>
                                            {/* Testimonial Author */}
                                            <h5 className="h5-xs">- pebz13</h5>
                                        </div>
                                    </div>
                                    {/* TESTIMONIAL #4 */}
                                    <div className="review-2 radius-08">
                                        {/* App Rating */}
                                        <div className="app-rating ico-20 yellow-color">
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star-1" />
                                        </div>
                                        {/* Title */}
                                        <h5 className="h5-sm">Best App for iOS!</h5>
                                        {/* Testimonial Text */}
                                        <div className="review-2-txt">
                                            {/* Text */}
                                            <p className="p-lg grey-color">An augue in cubilia laoreet magna and suscipit egestas magna ipsum purus ipsum
                                                and suscipit
                                            </p>
                                            {/* Testimonial Author */}
                                            <h5 className="h5-xs">- Scott Boxer</h5>
                                        </div>
                                    </div>
                                    {/* TESTIMONIAL #5 */}
                                    <div className="review-2 radius-08">
                                        {/* App Rating */}
                                        <div className="app-rating ico-20 yellow-color">
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star-half-empty" />
                                        </div>
                                        {/* Title */}
                                        <h5 className="h5-sm">Awesome Design!</h5>
                                        {/* Testimonial Text */}
                                        <div className="review-2-txt">
                                            {/* Text */}
                                            <p className="p-lg grey-color">Mauris donec magnis sapien undo etiam sapien and congue augue egestas ultrice
                                                a vitae purus velna integer tempor
                                            </p>
                                            {/* Testimonial Author */}
                                            <h5 className="h5-xs">- John Sweet</h5>
                                        </div>
                                    </div>
                                    {/* TESTIMONIAL #6 */}
                                    <div className="review-2 radius-08">
                                        {/* App Rating */}
                                        <div className="app-rating ico-20 yellow-color">
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                        </div>
                                        {/* Title */}
                                        <h5 className="h5-sm">Simply Amazing App!</h5>
                                        {/* Testimonial Text */}
                                        <div className="review-2-txt">
                                            {/* Text */}
                                            <p className="p-lg grey-color">An augue cubilia laoreet undo magna a suscipit undo egestas magna ipsum ligula
                                                vitae purus ipsum primis cubilia blandit
                                            </p>
                                            {/* Testimonial Author */}
                                            <h5 className="h5-xs">- Leslie D.</h5>
                                        </div>
                                    </div>
                                    {/* TESTIMONIAL #7 */}
                                    <div className="review-2 radius-08">
                                        {/* App Rating */}
                                        <div className="app-rating ico-20 yellow-color">
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star-half-empty" />
                                        </div>
                                        {/* Title */}
                                        <h5 className="h5-sm">Powerful Features!</h5>
                                        {/* Testimonial Text */}
                                        <div className="review-2-txt">
                                            {/* Text */}
                                            <p className="p-lg grey-color">Augue egestas volutpat and egestas augue in cubilia laoreet magna undo
                                                suscipit luctus
                                            </p>
                                            {/* Testimonial Author */}
                                            <h5 className="h5-xs">- Marisol19</h5>
                                        </div>
                                    </div>
                                    {/* TESTIMONIAL #8 */}
                                    <div className="review-2 radius-08">
                                        {/* App Rating */}
                                        <div className="app-rating ico-20 yellow-color">
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star-half-empty" />
                                        </div>
                                        {/* Title */}
                                        <h5 className="h5-sm">Super Support!</h5>
                                        {/* Testimonial Text */}
                                        <div className="review-2-txt">
                                            {/* Text */}
                                            <p className="p-lg grey-color">Aliquam augue suscipit luctus neque purus ipsum neque dolor primis libero tempus
                                                at blandit posuere varius magna
                                            </p>
                                            {/* Testimonial Author */}
                                            <h5 className="h5-xs">- AJ</h5>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}
export default ReviewSlider;