import React, { useState, useEffect } from 'react';

const Contacts = () => {

  const [formData, setFormData] = useState({});
  let [data_Storage, setData_Storage] = useState();
  const [errors, setErrors] = useState({})

  // All filled validation
  const validation = () => {
    let nameErr, topicErr, emailErr = true

    // choose topic
    if (formData.topic != undefined) {
      if (formData.topic == "0") {
        setErrors(errors => ({ ...errors, topic: "Please select topic" }))
      } else {
        setErrors(errors => ({ ...errors, topic: "" }))
        topicErr = false;
      }
    }

    // name
    if (formData.name != undefined) {
      if (formData.name == "") {
        setErrors(errors => ({ ...errors, name: "Enter your name" }))
      } else {
        var regex = /^[a-zA-Z\s]+$/;
        if (regex.test(formData.name) === false) {
          setErrors(errors => ({ ...errors, name: "Please enter valid name" }))
        } else {
          setErrors(errors => ({ ...errors, name: "" }))
          nameErr = false;
        }
      }
    }

    // email
    if (formData.email != undefined) {
      if (formData.email == "") {
        setErrors(errors => ({ ...errors, email: "Enter your email address" }))
      } else {
        // Regular expression for basic email validation
        var regex = /^\S+@\S+\.\S+$/;
        if (regex.test(formData.email) === false) {
          setErrors(errors => ({ ...errors, email: "Please enter a valid email address" }))
        } else {
          setErrors(errors => ({ ...errors, email: "" }))
          emailErr = false;
        }
      }
    }

    // if all input value true then success
    if ((topicErr || nameErr || emailErr) == true) {
      return false
    } else {
      return true
    }
  }

  // form submit
  const submitData = (e) => {
    e.preventDefault();
    let isValid = true  // assume that form is validated

    // check all input filled value check through "validation()"" function
    isValid = validation()

    if (formData.topic == undefined) {
      setErrors(errors => ({ ...errors, topic: "Please select topic..." }))
      isValid = false
    }
    if (formData.name == undefined) {
      setErrors(errors => ({ ...errors, name: "Please enter your name..." }))
      isValid = false
    }
    if (formData.email == undefined) {
      setErrors(errors => ({ ...errors, email: "Please enter email..." }))
      isValid = false
    }

    // set form data in LocalStorage
    let localStorage_data = JSON.parse(localStorage.getItem('formLocalData_Storage')) || [];

    if (isValid) {
      localStorage_data.push(formData)
      localStorage.setItem('formLocalData_Storage', JSON.stringify(localStorage_data));

      setData_Storage(localStorage_data)
      console.table(data_Storage)

      setFormData({});
      alert("Form submit successfully !");

      return true
    } else {
      return false
    }
  }

  useEffect(
    () => {
      validation()
    },
    [formData])
  return (
    <>
      {/* PAGE CONTENT */}
      <div id="page" className="page">

        {/* CONTACT-2 */}
        <section id="contacts-2" className="bg_whitesmoke hero-offset-nav pb-50 contacts-section division">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="section-title text-center mb-70">
                  <h2 className="h2-md">How Can We Help?</h2>
                  <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                    tempus, blandit and cursus varius magna tempus a dolor
                  </p>
                </div>
              </div>
            </div>
            {/* CONTACT FORM */}
            <div className="row">
              <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                <div className="form-holder">

                  <form name="contactform" className="row contact-form" onSubmit={(e) => submitData(e)}>

                    {/* Contact Form Select - Choose Topic */}
                    <div className="col-md-12 input-subject">
                      <p className="p-lg">This question is about: </p>
                      <span>Choose a topic, so we know who to send your request to: </span>
                      <select name='topic' value={!formData.topic == 0 ? formData.topic : ''} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} className="custom-select subject">
                        <option>This question is about...</option>
                        <option>Registering/Authorising</option>
                        <option>Using Application</option>
                        <option>Troubleshooting</option>
                        <option>Backup/Restore</option>
                        <option>Other</option>
                      </select>
                      <span className="errMsg" style={{ color: 'red', fontSize: '14px', position: 'absolute', bottom: '-10px' }}>{errors.topic != undefined ? errors.topic : ''}</span>
                    </div>

                    {/* Contact Form Input - Name */}
                    <div className="col-md-12">
                      <p className="p-lg">Your Name: </p>
                      <span>Please enter your real name: </span>
                      <input type="text" name='name' value={!formData.name == '' ? formData.name : ''} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} className="form-control name" placeholder="Your Name*" />
                      <span className="errMsg" style={{ color: 'red', fontSize: '14px', position: 'absolute', bottom: '-10px' }}>{errors.name != undefined ? errors.name : ''}</span>
                    </div>

                    {/* Contact Form Input - Email */}
                    <div className="col-md-12">
                      <p className="p-lg">Your Email Address: </p>
                      <span>Please carefully check your email address for accuracy</span>
                      <input type="text" name="email" value={!formData.email == '' ? formData.email : ''} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} className="form-control email" placeholder="Email Address*" />
                      <span className="errMsg" style={{ color: 'red', fontSize: '14px', position: 'absolute', bottom: '-10px' }}>{errors.email != undefined ? errors.email : ''}</span>
                    </div>

                    {/* Contact Form Textarea - Message */}
                    <div className="col-md-12 input-message">
                      <p className="p-lg">Explain your question in details: </p>
                      <span>Your OS version, Descode version &amp; build, steps you did. Be VERY precise!</span>
                      <textarea className="form-control message" name="message" value={!formData.message == '' ? formData.message : ''} onChange={(e) => setFormData({ ...formData, message: e.target.value })} rows={6} placeholder="I have a problem with..." />
                    </div>

                    {/* Contact Form Button */}
                    <div className="col-md-12 mt-15 form-btn text-right">
                      <button type="submit" className="btn btn-skyblue tra-skyblue-hover submit">Submit Request</button>
                    </div>

                  </form>
                  {/* SECTION DIVIDER */}
                  <div className="divider-wrapper text-center bg_whitesmoke"><div className="section-divider" /></div>
                </div>
              </div>
            </div>
          </div>
          {/* GEOMETRIC OVERLAY */}
          <div className="bg_fixed geometric_overlay" />
        </section>
      </div>
    </>
  );
}

export default Contacts;