import React, { useState, useEffect } from 'react';
const Loader = () => {

    const [isLoader, setIsLoader] = useState(false);

    useEffect(() => {
        setIsLoader(true);
        window.addEventListener("load", setLoaderHandle);
        setLoaderHandle();
        return () => {
            window.removeEventListener("load", setLoaderHandle);
        };
    }, []);

    const setLoaderHandle = () => {
        const time = setTimeout(() => setIsLoader(false), 1300);
    }

    return (
        <>
            {isLoader !== false

                ?
                <div id="loader-wrapper">
                    <div id="loading">
                        <span className="cssload-loader">
                            <span className="cssload-loader-inner"></span>
                        </span>
                    </div>
                </div>
                :
                null
            }
        </>
    )



}

export default Loader;