import React from 'react';
import { Link } from 'react-router-dom';

const Error = () => {
    return (
        <>
            {/* PAGE CONTENT */}	
            <div id="page" className="page">                
                <section id="contacts-2" className="bg_whitesmoke hero-offset-nav error_page pb-100">				
                    <div className="container">
                        {/* SECTION TITLE */}	
                        <div className="row">	
                            <div className="col-lg-10 offset-lg-1">
                            <div className="section-title text-center mb-70">		
                                {/* Title */}	
                                <h2 className="h2-md">404</h2>
                                <h3 className="h2-md">...Oops! Something is missing</h3>	
                                {/* Text */}	
                                <p className="p-xl">
                                    <Link to='/' className="btn btn-skyblue tra-grey-hover last-link">Go Home</Link>
                                </p>
                            </div>	
                            </div>
                        </div>             
                    </div>
                    {/* GEOMETRIC OVERLAY */}	
                    <div className="bg_fixed geometric_overlay" />
                </section>                 
            </div>	            
        </>
    );
}

export default Error;
