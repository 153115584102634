import React from 'react';
import WOW from "wowjs";
import Banner8 from '../components/main-banner/Banner8';
import Features_Info from '../components/Features_Info';
import ChatWithFriends from '../components/ChatWithFriends';
import CounterSection from '../components/Counter_Section';
import Info_Img_Content from '../components/Info_Img_Content';
import Info_Content_Img from '../components/Info_Content_Img';
import Download_bgImage_Banner from '../components/banner/Download_bgImage_Banner';
import Reviews from '../components/Reviews';
import BrandSlider from '../components/Brand-Slider';
import Blog from '../components/Blog';
import NewsLetter from '../components/NewsLetter';
import Info_Content_Img_bg from '../components/Info_Content_Img_bg';

class Home8 extends React.Component {
  componentDidMount() {
    const wow = new WOW.WOW(
      { live: false }
    );
    wow.init();
  }
  render() {
    return (
      <>
        {/* PAGE CONTENT */}
        <div id="page" className="page">

          {/* BANNER-EIGHT */}
          <Banner8
            // All Props information is preset in "components/main-banner/Banner6.jsx"
            section_class={``}
            font_color={``}
            banner_sub_title={`DesCode®`}
            banner_title={`Your Activity Feed`}
            banner_description={`Stay Connected with Your Friends`}
            app_store_img={`store/appstore.png`}
            appstore_link={``}
            play_store_img={`store/googleplay.png`}
            playstore_link={``}
            user_Count={`7.296`}
            banner_main_img={`home/hero-8-img.png`}
          />

          {/* FEATURES-INFO */}
          <Features_Info
            // All Props information is preset in "components/Features_info.jsx"
            layout_style={2}
            section_class={`wide-50`}
            sec_title={``}
            sec_description={``}
            info_data={[
              {
                icon: `flaticon-user-1`,
                title: `Multiple Accounts`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-smartphone-1`,
                title: `Text, Voice & Video Calls`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-image`,
                title: `Share Files & Media`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-chat-4`,
                title: `Alerts & Notifications`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-fingerprint`,
                title: `Online Verification`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              },
              {
                icon: `flaticon-spam`,
                title: `Spam Protection`,
                description: `Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat at impedit felis undo auctor augue mauris`
              }
            ]}
            icon_color={'stateblue-color'}
          />

          {/* SECTION DIVIDER */}
          <div className="divider-wrapper text-center"><div className="section-divider" /></div>

          {/* CHAT WITH FRIENDS */}
          <ChatWithFriends
            // All Props information is preset in "components/ChatWithFriends.jsx"		
            section_class={`pt-100`}
            sec_title={`Chat with Your Friends Easily`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            sec_img={`home/socials.png`}
            button={false}
            button_title={``}
            button_bg_and_hover={``}
            button_icon={``}
            button_description={``}
          />

          {/* COUNTER SECTION */}
          <CounterSection
            // All Props information is preset in "components/Counter_Section.jsx"	
            section_class={`wide-60 `}
            sec_title={``}
            sec_description={``}
            Counter_data={
              [
                {
                  counter_number: 78,
                  counter_title: 'Faster Access'
                },
                {
                  counter_number: 52,
                  counter_title: 'App Productivity'
                },
                {
                  counter_number: 82,
                  counter_title: 'Secure Access'
                },
                {
                  counter_number: 44,
                  counter_title: 'Battery Saving'
                }
              ]
            }
            countText_color={`text-purple-gradient`}
          />

          <Info_Content_Img_bg
            // All Props information is preset in "Info_Content_Img_bg.Jsx"
            section_class={`mb-60`}
            content_bg={`lavender_gradient`}
            sub_title={``}
            title={`Work smarter with powerful automation`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus at blandit and purus ipsum primis and cubilia laoreet augue a luctus magna dolor luctus mauris pretium a sapien egestas luctus`,
                `Fringilla risus, luctus mauris auctor a purus euismod orci pretium purus pretium undo ligula rutrum tempor magna dolor`
              ]
            }
            main_image={`home/img-12.png`}
          />

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`wide-70`}
            lft_image={`home/img-07.png`}
            img_Class={``}
            sub_title={`Unlimited Access`}
            title={`Spend your time with loved ones`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus at egestas sapien vitae. Nemo egestas volute and turpis dolores aliquam quaerat sodales a sapien`,
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus and egestas sapien egestas vitae nemo volute`
              ]
            }
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`pb-60`}
            sub_title={`Fastest Messaging`}
            title={`Unlimited calling, texting and picture messaging`}
            p_content_data={
              [
                `Aliqum mullam blandit and tempor sapien donec ipsum gravida porta. Velna vitae auctor
                congue magna impedit ligula risus. Mauris donec ligula and magnis undo sapien sagittis sapien pretium
                enim gravida purus ligula`
              ]
            }
            rtl_image={`home/img-05.png`}
            img_Class={`pc-15`}
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={`bg_el_01`}
            bgShape_design_2={`bg_el_05`}
          />

          {/* FEATURES-INFO*/}
          <Features_Info
            // All Props information is preset in "components/Features_Info.jsx"
            layout_style={3}
            section_class={`pb-100`}
            sec_title={`Get Ready to Be Surprised`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            info_data={
              [
                {
                  title: `Dark & Light Modes`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-paint-brush`
                },
                {
                  title: `Auto-Link Opening`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-smartphone-8`
                },
                {
                  title: `Voice Assistant`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-voice-message-1`
                },
                {
                  title: `Task Scheduling`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-tasks`
                }
              ]
            }
            icon_color={''}
          />

          {/* DOWNLOAD BG IMAGE */}
          <Download_bgImage_Banner
            // All Props information is preset in "components/Download_bgImage_Banner.jsx"		
            section_class={`bg_chat`}
            sub_title={`Voice & Video Calls`}
            title={`Keep Your Favorite People Close to You`}
            description={`Aliquam a augue suscipit, luctus neque at purus ipsum neque dolor primis libero tempus, blandit 
                          posuere orci auctor purus euismod an aliquam quaerat purus`}
            img1={'store/appstore.png'}
            img2={'store/googleplay.png'}
          />

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`wide-70`}
            lft_image={`home/img-02.png`}
            img_Class={``}
            sub_title={`Beauty of Simplicity`}
            title={`Beautiful, award-winning design`}
            p_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`
              ]
            }
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`pb-60`}
            sub_title={`Totally Optimized`}
            title={`Intuitive features, powerful results`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`,
              ]
            }
            button={true}
            button_bg_and_hover={`btn-tra-grey skyblue-hover`}
            button_content={`Find Out More`}
            rtl_image={`home/tablet.png`}
            img_Class={`content-13-img`}
            bgShape_design_1={''}
            bgShape_design_2={''}
          />

          {/* REVIEWS SIMPLE */}
          <Reviews
            // All Props information is preset in "components/Reviews.jsx"
            section_class={`bg_aliceblue wide-60`}
            section_title={`Our Happy Customers`}
            section_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor `}
            app_store_img={`store/appstore.png`}
            appstore_link={``}
            play_store_img={`store/googleplay.png`}
            playstore_link={``}
            app_version_desc={`Available for iOS 8 and Android Devices From 5.5`}
          />

          {/* INFO-LEFT-CONTENT-RIGHT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`wide-50`}
            sub_title={`Register in 30 Seconds`}
            title={`Lightning fast and super powerful`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus at egestas sapien vitae nemo egestas volute and turpis dolores aliquam quaerat sodales a sapien`,
              ]
            }
            button={``}
            button_bg_and_hover={``}
            button_content={``}
            rtl_image={`home/img-01.png`}
            img_Class={``}
            bgShape_design_1={''}
            bgShape_design_2={''}
          />

          {/* BRANDS-SLIDER */}
          <BrandSlider
            // All Props information is preset in "Brand-Slider.Jsx"
            section_class={`pb-70`}
            sec_title={`You might know Descode from:`}
            brand_images={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          />

          {/* SECTION DIVIDER */}
          <div className="divider-wrapper text-center"><div className="section-divider" /></div>

          {/* BLOG */}
          <Blog
            // All Props information is preset in "components/Blog.jsx"
            section_class={`wide-60`}
            sec_title={`Our Tips and Latest News`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor `}
            itemStart={6}
            itemEnd={9}
          />

          {/* NEWSLETTER */}
          <NewsLetter
            // All Props information is preset in "components/NewsLetter.jsx"	
            section_class={`pb-20`}
            sub_title={`Subscribe to Our Newsletter`}
            title={`Stay up to date with our news, ideas and updates`}
            input_placeholder={``}
            btn_content={`Subscribe Now`}
            button_bg_and_hover={`btn-rose tra-rose-hover`} //Like btn-tra-grey skyblue-hover					
          />

        </div>
        {/* END PAGE CONTENT */}
      </>
    )
  }
}
export default Home8; 