import React from 'react';
import WOW from "wowjs";
import Features_Info from '../components/Features_Info';
import Banner4 from '../components/main-banner/Banner4';
import Info_Img_Content from '../components/Info_Img_Content';
import Info_Content_Img from '../components/Info_Content_Img';
import Accessible_All_Platform_Banner from '../components/Accessible_All_Platform_Banner';
import Reviews from '../components/Reviews';
import Pricing from '../components/Pricing';
import Brand from '../components/Brand';
import Faqs from '../components/Faqs';
import Download_bgPattern_Banner from '../components/banner/Download_bgPattern_Banner';
import Info_Content_Img_bg from '../components/Info_Content_Img_bg';

class Home4 extends React.Component {
  componentDidMount() {
    const wow = new WOW.WOW(
      { live: false }
    );
    wow.init();
  }
  render() {
    return (
      <>
        {/* PAGE CONTENT*/}
        <div id="page" className="page">

          {/* BANNER-FOUR */}
          <Banner4
            // All Props information is preset in "components/main-banner/Banner4.jsx"
            section_class={``}
            font_color={`white-color`}
            banner_title={`Get fast and secure access to all your favorite content`}
            app_store_img={`store/appstore.png`}
            appstore_link={``} // appstore link
            play_store_img={`store/googleplay.png`}
            playstore_link={``}
            info_description={`Available for iOS 8 and Android Devices From 5.5`}
            banner_content_img={`home/hero-4-img.png`}
          />

          {/* FEATURES-INFO */}
          <Features_Info
            // All Props information is preset in "components/Features_Info.jsx"
            layout_style={5}
            section_class={`wide-50`}
            sec_title={``}
            sec_description={``}
            icon_color={`skyblue-color`}
            info_data={[
              {
                title: `File Manager`,
                icon: `flaticon-email`,
                description: `Porta semper lacus and cursus risus undo it a ultrice sodales tempus egestas sapien`
              },
              {
                title: `Contacts Manager`,
                icon: `flaticon-users`,
                description: `Porta semper lacus and cursus risus undo it a ultrice sodales tempus egestas sapien`
              },
              {
                title: `Make Ringtones`,
                icon: `flaticon-alarm`,
                description: `Porta semper lacus and cursus risus undo it a ultrice sodales tempus egestas sapien`
              },
              {
                title: `Convert Media Files`,
                icon: `flaticon-roundabout`,
                description: `Porta semper lacus and cursus risus undo it a ultrice sodales tempus egestas sapien`
              },
              {
                title: `Files Sharing`,
                icon: `flaticon-harddisk`,
                description: `Porta semper lacus and cursus risus undo it a ultrice sodales tempus egestas sapien`
              },
              {
                title: `Storage & Backup`,
                icon: `flaticon-harddisk-1`,
                description: `Porta semper lacus and cursus risus undo it a ultrice sodales tempus egestas sapien`
              }
            ]}
          />

          <Info_Content_Img_bg
            // All Props information is preset in "Info_Content_Img_bg.Jsx"
            section_class={`mb-60`}
            content_bg={`lavender_gradient`}
            sub_title={``}
            title={`Work smarter with powerful automation`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus at blandit and purus ipsum primis and cubilia laoreet augue a luctus magna dolor luctus mauris pretium a sapien egestas luctus`,
                `Fringilla risus, luctus mauris auctor a purus euismod orci pretium purus pretium undo ligula rutrum tempor magna dolor`
              ]
            }
            main_image={`home/img-12.png`}
          />

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`wide-70`}
            lft_image={`home/img-02.png`}
            img_Class={``}
            sub_title={`Perfect Integration`}
            title={`More productivity with less effort`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus at egestas sapien vitae nemo egestas volute and turpis dolores aliquam quaerat sodales a sapien`,
              ]
            }
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* INFO-LEFT-UL_CONTENT-RIGHT-IMG-TWO */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`mb-100`}
            sub_title={``}
            title={``}
            icon_content_data={
              [
                {
                  icon: `flaticon-tasks`,
                  title: `Add Notes & Tags`,
                  description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
                },
                {
                  icon: `flaticon-sync`,
                  title: `Convert Photo & Video`,
                  description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
                },
                {
                  icon: `flaticon-image`,
                  title: `Share Files & Media`,
                  description: `Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at suscipit quaerat ultrice tellus viverra `
                }
              ]
            }
            icon_color={'skyblue-color'}
            rtl_image={`home/img-08.png`}
            img_Class={`pc-15`}
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* FEATURES-INFO*/}
          <Features_Info
            // All Props information is preset in "components/Features_Info.jsx"
            layout_style={3}
            section_class={`pb-100`}
            sec_title={`Get Ready to Be Surprised`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            info_data={
              [
                {
                  title: `Quick Access`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-call`
                },
                {
                  title: `Powerful Options`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-tools`
                },
                {
                  title: `Multiple Devices`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-smartphone-8`
                },
                {
                  title: `Battery Saving`,
                  description: `Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna at vitae congue tempus`,
                  icon: `flaticon-phone-1`
                }
              ]
            }
            icon_color={''}
          />

          {/* INFO-LEFT-UL_CONTENT-RIGHT-IMG-TWO */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`bg_shape_02 content-section crocus_gradient rel wide-60 white-color`}
            sub_title={`Totally Optimized`}
            title={`Intuitive features, powerful results`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo pretium a purus mauris`,
              ]
            }
            button={true}
            button_bg_and_hover={`btn-tra-white skyblue-hover`}
            button_content={`Find Out More`}
            rtl_image={`home/tablet.png`}
            img_Class={`content-13-img`}
            bgShape_design_1={''}
            bgShape_design_2={''}
          />

          {/* INFO-LEFT-IMG-RIGHT-CONTENT */}
          <Info_Img_Content
            // All Props information is preset in "Info_Img_Content.Jsx"
            section_class={`wide-100`}
            lft_image={`home/img-03.png`}
            img_Class={``}
            sub_title={`Beauty of Simplicity`}
            title={`Beautiful, award-winning design`}
            ul_content_data={
              [
                `Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium
                ligula rutrum tempor sapien`,
                `Quaerat sodales sapien euismod purus blandit`,
                `Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo
                pretium a purus mauris`
              ]
            }
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* ACCESSIBLE ALL PLATFORM */}
          <Accessible_All_Platform_Banner
            // All Props information is preset in "components/Accessible_All_Platform_Banner.jsx"
            section_class={``}
            sec_title={`Accessible for All Platforms`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
            sec_img={`home/browsers.png`}
            button={true} // true/false
            button_content={`See Descode in Action`}
            button_bg_and_hover={`btn-tra-grey skyblue-hover`} // btn-tra-grey skyblue-hover
            button_icon={`flaticon-play-button`} // flaticon-play-button
            button_description={`Available on Android, iOS and macOS`}
          />

          {/* REVIEWS SIMPLE */}
          <Reviews
            // All Props information is preset in "components/Reviews.jsx"
            section_class={`bg_aliceblue wide-60`}
            section_title={`Why Customers Love Us`}
            section_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor `}
            app_store_img={`store/appstore.png`}
            appstore_link={``}
            play_store_img={`store/googleplay.png`}
            playstore_link={``}
            app_version_desc={`Available for iOS 8 and Android Devices From 5.5`}
          />

          {/* INFO-CONTENT-IMG */}
          <Info_Content_Img
            // All Props information is preset in "Info_Content_Img.Jsx"
            section_class={`wide-100`}
            sub_title={`Register in 30 Seconds`}
            title={`Lightning fast and super powerful`}
            p_content_data={
              [
                `Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue luctus magna dolor luctus at egestas sapien vitae nemo egestas volute and turpis dolores aliquam quaerat sodales a sapien`,
              ]
            }
            rtl_image={`home/img-01.png`}
            img_Class={`pc-15`}
            button={``}
            button_content={``}
            button_bg_and_hover={``}
            button_icon={``}
            bgShape_design_1={``}
            bgShape_design_2={``}
          />

          {/* PRICING-2 */}
          <Pricing
            // All Props information is preset in "components/Pricing.jsx"		
            section_class={`pb-100`}
            sec_title={`Simple and Flexible Pricing`}
            sec_description={`Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero tempus, blandit and cursus varius magna tempus a dolor`}
          />

          {/* BRANDS-2 */}
          <Brand
            // All Props information is preset in "components/Brand.jsx"
            section_class={`bg_whitesmoke `}
            sec_title={`You might know Descode from:`}
            brand_images={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          />

          {/* FAQS */}
          <Faqs
            // All Props information is preset in "components/Faqs.jsx"
            section_class={``}
            sub_title={`FAQs`}
            title={`Got Questions? Look Here`}
            content={`Aliquam augue suscipit luctus neque purus ipsum neque dolor primis undo tempus, blandit and cursus varius`}
          />


          {/* DOWNLOAD BG PATTERN */}
          <Download_bgPattern_Banner
            // All Props information is preset in "components/Download_bgPattern_Banner.jsx"	
            section_class={'rel violet_gradient bg_shape_02 pt-100'}
            sec_img={`home/img-15.png`}
            title={`Save your time & effort with Descode`}
            content={`Augue egestas volutpat egestas augue purus cubilia laoreet and magna suscipit luctus dolor tempus`}
            img1={'store/appstore.png'}
            img2={'store/googleplay.png'}
            user={`7.296`}
          />

        </div>
        {/* END PAGE CONTENT */}
      </>
    )
  }
}
export default Home4; 